<template>
<div>
  <BannerPandOne :pand="pand" v-if="pand" />
</div>
</template>

<script>
import BannerPandOne from '../../components/Panden/PandOne/BannerPandOne.vue'

export default {
  name: 'PandOne',
  components:{
    BannerPandOne
  },
  data:function(){
    return{
      pand:null
    }
  },
  async created(){
    await this.$store.dispatch('pandenStore/getPandFromId', this.$route.params.id);
    this.pand = this.$store.state.pandenStore.pandFromId;
  }
}
</script>

<style scoped lang="scss">

</style>