<template>
  <Usp :doelstellingen="doelstellingen" />
</template>

<script>
import Usp from '../../components/Widgets/CarousselCTAItem.vue'

export default {
  name: 'Home',
  components: {
    Usp
  },
  computed:{
    doelstellingen(){
        if(this.$store.state.uspStore.doelstellingen.items)
        {
          var res = this.$store.state.uspStore.doelstellingen.items

          res.forEach(element => {
            element.forEach(el=>{
              el.button = "Acties";
            })
          });

          return res;
        }

        return this.$store.state.uspStore.doelstellingen.items;
    }
  },
  async created(){
    await this.$store.dispatch('uspStore/getDoelstellingen');
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>