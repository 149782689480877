<template>
  <div v-if="ActiePlanning" id="acts">
    <Intro 
      :imageGauche="true"
      @bekijken="openBekijken()"
      :btnBekijken="true"
      :btnDoen="false"
      :info="ActiePlanning.actieS_INFOTACTIEPLANNING"
      :title="'Actieplanning'"
      :image="ActiePlanning.actieS_AFBEELDINGTACTIEPLANNING"
      :imageUrl="'SKWINKELNetActieActieplanning'"
      :icon="'SKWINKELNetActieActieplanningIcoontje'"
    />

    <section v-if="ActiePlanning.planningen && ActiePlanning.planningen.length > 0 && openen" id="Planning">
      <div class="container">
        <div class="list-group list-group-flush">
          <div v-for="item in ActiePlanning.planningen" :key="item.actieplanningeN_ID" class="list-group-item">
            <div class="row align-items-center">
                <div class="col-3">
                  {{item.actieplanningeN_AANVANG}} - {{item.actieplanningeN_EINDE}}
                </div>
                <div class="col-9">
                  {{item.actieplanningeN_OMSCHRIJVING}}
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-7">
        <button type="button" @click="openBekijken()" class="btn btn-primary">Sluiten</button>
      </div>
    </section>
  </div>
</template>

<script>
import Intro from './IntroTables/Intro.vue'

export default {
  name: 'ActiePlanning',
  props:{ actieId: null },
  data:function(){
    return{
      openen:false
    }
  },
  methods:{
    scrollToTop(){
        const id = 'acts';
        const yOffset = -150; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    },
    openBekijken(){
      this.openen = !this.openen;

      if(!this.openen)
        this.scrollToTop();
    }
  },
  components:{
    Intro
  },
  computed:{
    ActiePlanning(){
        return this.$store.state.actieStore.ActiePlanning ? 
          this.$store.state.actieStore.ActiePlanning.items : null;
    },
  },
  async created(){
    await this.$store.dispatch('actieStore/getActiePlanning', this.$route.params.id);
  }
}
</script>

<style scoped>
li{
  text-decoration: none;
}

ul{
  list-style-type: none;
  margin:0;
}

h1{
    font-weight: 900;
}

.titre{
  color:#5ca2be;
}
</style>