<!--
  Acceuille un objet de x3 avec une image, un titre et un texte
-->


<template>
    <div class="row align-items-center text-center text-md-start">
      <div v-if="doelstellingen" :id="'carouselUsps' + id" class="carousel slide"  data-bs-interval="false" data-aos="fade-up">
        <div class="carousel-inner">
          <template v-for="(item, index) in doelstellingen" :key="item.id">
            <div class="carousel-item" v-bind:class="{'active' : index == 0}">
              <div class="row align-item-center">
                <UspItem v-for="subitem in item" :key="subitem" :items="subitem"/>
              </div>
            </div>
          </template>
        </div>
        <button class="carousel-control-prev " type="button" :data-bs-target="'#carouselUsps' + id" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next margin-right" type="button" :data-bs-target="'#carouselUsps' + id" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import UspItem from './CTAItem.vue'

//importing bootstrap 5
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import * as Bootstrap from 'bootstrap'
import {uuidv4} from './../../helpers/handleResponse'

export default {
  name: 'Usp',
  props:{
    doelstellingen: null,
  },
  data:function(){
    return{
      id:null
    }
  },
  components : {
      UspItem
  },
  mounted(){
    var myCarousel = document.querySelector('#myCarousel')
    new Bootstrap.Carousel(myCarousel)
 },
 created(){
  this.id = uuidv4();
 }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.lanceAnim{
  margin-top: 200px;
  margin-bottom : 200px;
}

.colorTemplateBack{
  background-color: rgb(248, 248, 248);
}
</style>