<template>
    <h1>Nieuwe adres toevoegen</h1>

  <input 
    type="text" 
    v-model="searchterm"
    class="form-control"
    placeholder="Zoeken"
    autocomplete="off" 
  />

  <div v-for="item in adressen" :key="item.vestigingsadresseN_ID" @click="$emit('ajout',item.adresseN_ID)" class="list-group-item TumiClickable">
    <div class="align-items-center text-center row">
      <div class="col-3 text-start">
        {{item.adresseN_STRAATTNUMMERTBUS}}
      </div>
      <div class="col-3 text-start">
        {{JSON.parse(item.adresseN_POSTCODE)[0]}}
      </div>
      <div class="col-3 text-start">
        {{JSON.parse(item.adresseN_PLAATSNAAM)[0]}}
      </div>
      <div class="col-3 text-start">
        {{item.adresseN_DORPSKERN}}
      </div>
    </div>
  </div>

</template>
   
<script>
   export default {
     name: 'NewAdress',
     emits:['ajout'],
     data:function(){
      return{
        searchterm: "",
        variable: "adres"
      }
     },
     methods:{
      GetAdressen(searchterm){
        const path = '/portaal/SearchAdressen?searchField=' + searchterm;
        const stateName = this.variable;

        this.$store.dispatch('dynamicStore/getDynamicSolidState', {path, stateName});
      }
     },
     computed:{
      adressen(){
        return this.$store.state.dynamicStore[this.variable];
      }
     },
     watch:{
      searchterm(e){
        if(e)
          this.GetAdressen(e);
        else        
          this.$store.dispatch('dynamicStore/deleteSolidState','adres');
      }
     }
   }
</script>   

<style>

</style>