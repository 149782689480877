<template>

<section class="py-md-10" style="background-color: rgb(0,0,0,0.6); z-index: 0; height:450px">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 text-center">

            <h1 class="display-2 fw-bold text-white mt-11">
              {{project.projecteN_PROJECT}}            
            </h1>

          </div>
        </div> <!-- / .row -->
      </div> <!-- / .container -->
    <div id="jarallax-container-0" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; overflow: hidden; z-index: -100;"><div style="background-position: 50% 50%; background-size: cover; background-repeat: no-repeat; background-image: url(&quot;https://landkit.goodthemes.co/assets/img/covers/cover-4.jpg&quot;); position: absolute; top: 0px; left: 0px; width: 1423px; height: 467px; overflow: hidden; pointer-events: none; transform-style: preserve-3d; backface-visibility: hidden; will-change: transform, opacity; margin-top: 141.65px; transform: translate3d(0px, -104.088px, 0px);">
  </div>
</div>
</section>

<div>
</div>
  <section class="position-relative pt-12 pt-md-10 ">
    <div class="container"  style="font-size:1.1rem">
      <div class="row align-items-center text-center text-md-start">
            <p class="fs-lg text-muted mb-0 aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
              {{project.projecteN_TEKSTTHOMEPAGINA}}
            </p>
      </div>         
    </div>
  </section>
</template>

<script>
export default {
  name: 'Actie',
  props:{
      project: null
    }
}
</script>

<style scoped>

.p-5 {
  background-image: url("~@/assets/Surface.jpg");
  background-size: cover;
  height:350px;
}

h1{
    font-weight: 900;
}
</style>