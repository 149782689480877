<template>
  <h1 v-if="name" class="mb-3">{{name}}</h1>
  <p v-if="desc">{{desc}}</p>
  <div v-for="inp in inputs" :key="inp">
    
    <!--Text component-->
    <DynamicComponent v-if="!inp.type" :inputModel="inp" :ref="inp.name" />
    
    <!--Date Picker-->
    <div v-if="inp.type == 'Date'" class="row mt-5 g-0 mb-5">
      <div class="col-3 ps-5">
        {{inp.placeholder}}
      </div>
      <div class="col-9">
        <Datepicker v-model="inp.value" autoApply :format="'dd/MM/yyyy'" :enableTimePicker="false"/>
      </div>
    </div>
  
    <!--Multi-select-->
    <div v-if="inp.type == 'DropDown'" class="row mt-5 g-0 mb-5">
      <div class="col-3 ps-5">
        {{inp.placeholder}}
      </div>
      <div class="col-9">
        <DynamicSelect :inputModel="inp" :ref="inp.name"/>
      </div>
    </div>

  </div>
  <p class="text-danger" v-if="validate">{{ validate }}</p>
  <button class="btn btn-secondary float-end" @click="Submit">Opslaan</button>
  <div class="clearfix"></div>

</template>

<script>
import {parseModel} from '../../Tumi/ModelParser'
import {GetFromView} from '../../Tumi/ModelParser.js'

import DynamicComponent from './DynamicComponent.vue'
import Datepicker from '@vuepic/vue-datepicker';
import DynamicSelect from './DynamicSelect.vue'

import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'DynamicPage',
  components:{
    DynamicComponent,
    Datepicker,
    DynamicSelect
  },
  props:{
    objet: null,
    validate:null,
    name: null,
    desc: null,
  },
  emits:['action'],
  data: function(){
    return{
        inputs : null,
        date:null,
    }
  },
  methods:{
    Submit(){
        var ob = this.objet;
        ob = GetFromView(this.$refs, ob, this.inputs);
        this.$emit('action', ob);
    }
  },
  created(){
    this.inputs = parseModel(this.objet);
  },
  watch:{
    objet(value){
      if(value)
        this.inputs =  parseModel(value);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.flex-row{
    display: flex;
    flex-wrap: wrap;
}
</style>
