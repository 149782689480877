<template>
  <div v-if="Doelstellingen">
    <Intro
      :info="Doelstellingen.actieS_DOELSTELLINGEN"
      :title="'Doelstellingen'"
      :image="Doelstellingen.actieS_AFBEELDINGTDOELSTELLINGEN"
      :imageGauche="true"
      :imageUrl="'SKWINKELNetActieDoelstellingen'"
      :icon="'SKWINKELNetActieDoelstellingenIcoontje'"
    />
  </div>
</template>

<script>
import Intro from './IntroTables/Intro.vue'

export default {
  name: 'Doelstellingen',
  props:{ actieId: null },
  components:{
    Intro
  },
  computed:{
      Doelstellingen(){
          return this.$store.state.actieStore.Doelstellingen ? this.$store.state.actieStore.Doelstellingen.items : null;
      }
  },
  async created(){
      this.$store.dispatch('actieStore/getDoelstellingen', this.actieId);
  }
}
</script>

<style scoped>

</style>