import { userService } from '../../services/User/user.service';

const userStorage = localStorage.getItem('user');

//Keep logged between pages
const initialState = userStorage 
    ? { login: JSON.parse(userStorage), error : null } 
    : { login: null, error: null}

export const authenticationStore = {
    namespaced: true,
    state: initialState,
    getters:{
        isLoggedIn(state){
            return state.login;
        }
    },    
    actions: {
        async GetAccountFromGuid({commit}, guid ){
            commit("SetErrorToNull");
            await userService.GetAccountFromGuid(guid)
                .then(
                    res => commit('GetAccountFromGuidSucces', res),
                    error => commit('Failure', error))        
        },
        async CreateAccount({commit}, account ){
            commit("SetErrorToNull");
            await userService.CreateAccount(account)
                .then(
                    res => commit('CreateAccountSucces', res),
                    error => commit('Failure', error))        
        },
        async RetrievePass({commit}, email ){
            commit("SetErrorToNull");
            await userService.RetrievePass(email)
                .catch(error => commit('Failure', error))
        },
        async CheckIfNewPassGuidExists({commit}, guid){
            commit("SetErrorToNull");
            await userService.CheckIfNewPassGuidExists(guid)
                .catch(error => commit('Failure', error))
        },
        async SaveNewPass({commit}, data){
            commit("SetErrorToNull");
            await userService.SaveNewPass(data)
                .then(
                    res => commit('SaveNewPassSucces', res),
                    error => commit('Failure', error))
        },
        async Authenticate({commit},data){
            commit("SetErrorToNull");

            await userService.Authenticate(data)
                .then(
                    res => commit('AuthenticateSucces', res),
                    error => commit('AuthenticateError', error))
        },
        Logout({commit}){ commit('Logout');}
    },
    mutations: {
        SetErrorToNull(state){
            state.error = null;
        },
        Failure(state, error){
            console.log(error);
            state.error = error;
        },

        GetAccountFromGuidSucces(state, data){
            state.account = data.value;
        },

        CreateAccountSucces(state, data){
            state.createAccount = data;
        },

        SaveNewPassSucces(state, data){
            state.newPass = data;
        },
        AuthenticateSucces(state, data){
            state.error = null;
            state.login = data;
            
            //keep info of logged login in local storage
            if (data.token)
                localStorage.setItem('user', JSON.stringify(data));
        },
        AuthenticateError(state, error){
            state.login = null;
            state.error = error;
        },

        Logout(state){
            state.login = null;
            localStorage.removeItem('user');
        }
    }
}
