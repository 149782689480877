<template>
<div v-if="Account" class="row align-items-center justify-content-center gx-0 min-vh-90">
    <div class="col-12 col-md-5 col-lg-4">
      <img src="@/assets/Account/Aanmelden5FA7C4.png" class="img-fluid mb-5"><br/>
      <!-- Heading -->
      <h1 class="mb-7 fw-bold text-center">
        Meld je aan op het SKWINKELNet.
      </h1>

      <!-- Form -->
      <div class="form-group mb-5">
        <label class="form-label" for="email">
          E-mail
        </label>
        <input 
          v-model="Account.email" 
          id="email" 
          type="email" 
          class="form-control"
          style="pointer-events: none;">
      </div>

      <!-- Password1 -->
      <div class="form-group mb-5">
        <label class="form-label" for="password1">
          Wachtwoord
        </label>
        <input 
          v-model="password1" 
          id="password1" 
          type="password" 
          class="form-control" 
          placeholder="Vul jouw wachtwoord in.">
      </div>

      <p class="text-danger" v-if="error">{{error}}</p>

      <p class="text-danger text-center" v-if="AccountNietActief">Dit account is niet actief. Contacteer <a href="mailto:skwinkelnet@skwinkel.be">skwinkelnet@skwinkel.be</a> voor meer info.</p>

      <!-- Submit -->
      <button class="btn w-100 btn-secondary" @click="Create">
        Aanmelden
      </button>
      <div class="text-center mt-1">
        <a @click="Vergeten" style="cursor: pointer;">Wachtwoord vergeten?</a><br/>
        <span class="text-danger">{{SendEmail}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountLogin',
  props:{
    AccountProp: null
  },
  data:function(){
    return{
      Account: this.AccountProp,
      error:null,
      AccountNietActief: false,
      password1:"",
      SendEmail:""
    }
  },
  methods:{
    async Vergeten(){
      await this.$store.dispatch('authenticationStore/RetrievePass', this.Account.email);
      this.SendEmail = "Er werd een e-mail verstuurd met een link waarmee je jouw wachtwoord opnieuw kan instellen. Check ook je spambox.";
    },
    async Create(){

      if(this.password1 == "")
      { 
        this.error = "Wachtwoord veld is verplicht.";
        return;
      }  

      var res = {
        "password" : this.password1,
        "username":this.Account.email,
      }

      await this.$store.dispatch('authenticationStore/Authenticate', res);

      if(this.$store.state.authenticationStore.error)
      {
        if(this.$store.state.authenticationStore.error == "Account niet actief.")
        {
          this.AccountNietActief = true;
        }
        else
          this.error = this.$store.state.authenticationStore.error;
      }
      else
        this.$router.push('/portal');
    }
  }
}
</script>

<style>
a{
  color:#5ca2be !important;
}
a:hover{
  color:#41778c !important;
}
</style>