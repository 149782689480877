<template>
    <div v-if="pand" class="col-12 col-md-4 text-center">
        
        <router-link :to="{ name: 'pandOne', params: { id: pand.bedrijfsruimteS_ID }}" class="card" href="#!">
            <!-- Image -->
        <img v-if="pand && pand.bedrijfsruimteS_AFBEELDINGENT2080X1248" :src="JSON.parse(pand.bedrijfsruimteS_AFBEELDINGENT2080X1248).length > 0 ? JSON.parse(pand.bedrijfsruimteS_AFBEELDINGENT2080X1248)[0] : require('@/assets/1.jpg')" class="card-img-top">
        <img v-if="!pand || !pand.bedrijfsruimteS_AFBEELDINGENT2080X1248" :src="require('@/assets/1.jpg')" class="card-img-top"/>
            <!-- Body -->
            <div class="card-body">
                <h3 class="mt-3 mb-1">
                    <span v-if="pand">{{pand.bedrijfsruimteS_ADRES}}</span>
                </h3>

                <p class="text-muted mb-3 ">
                    <span v-if="pand">{{pand.bedrijfsruimteS_POSTCODE}} {{pand.bedrijfsruimteS_GEMEENTE}}</span>
                </p>

                <span class="badge rounded-pill bg-success mb-3 p-3" data-aos="fade-right" data-aos-delay="300">
                    <span v-if="pand.bedrijfsruimteS_STATUS" class="h5">{{pand.bedrijfsruimteS_STATUS}}</span>
                    <span v-if="!pand.bedrijfsruimteS_STATUS" class="h5">Te huur</span>
                </span>

                <p class="mt-3">
                    {{pand.bedrijfsruimteS_OMSCHRIJVING}}
                </p>

                <p class="mt-3">
                    {{pand.ruimteS_RUIMTE}}
                </p>
            </div>
        </router-link>  
    </div>
</template>

<script>
export default {
    name: 'Pand',
    props : {
        pand:{},
        index: null
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.btn{
    margin-top:20px;
}

.cardPand{
    background-color: #daddea;
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgb(0,0,0,0.2);
}

a{
    color:rgb(75, 75, 75)
}

a:hover{
    text-decoration: none;
    color:black;
}

</style>
