import { createStore } from 'vuex'
import { missieStore } from './Missie/missie.store'
import { uspStore } from './Doelstellingen/usp.store'
import { actieStore } from './Actie/actie.store'
import { pandenStore } from './Panden/panden.store'
import { ledenStore } from './Leden/leden.store'
import {authenticationStore} from './Authenticate/authenticate.store'
import {organisatiesStore} from './Organisaties/organisaties.store'
import {homeStore} from './Home/home.store'
import {raadStore} from './Raad/raad.store'
import { formsStore } from './Forms/forms.store'
import {dynamicStore} from './Dynamic/dynamic.store'

export default createStore({
  modules: {
    homeStore : homeStore,
    missieStore : missieStore,
    uspStore : uspStore,
    actieStore : actieStore,
    pandenStore : pandenStore,
    ledenStore : ledenStore,
    authenticationStore : authenticationStore,
    organisatiesStore : organisatiesStore,
    raadStore : raadStore,
    formsStore : formsStore,
    dynamicStore : dynamicStore
  }
})