<template>
  <div v-if="Account">
    <div class="container d-flex flex-column mb-5">
        <AccountLogin v-if="!Account.newUser" :AccountProp="Account"/>
        <CreateAccount v-if="Account.newUser" :AccountProp="Account"/>
    </div>
  </div>
</template>

<script>
import AccountLogin from './AccountLogin.vue'
import CreateAccount from './CreateAccount.vue'

export default {
  name: 'Account',
  components:{
    AccountLogin,
    CreateAccount
  },
  computed:{
    Account(){
      return this.$store.state.authenticationStore.account;
    }
  },
  async created(){
      await this.$store.dispatch('authenticationStore/GetAccountFromGuid', this.$route.params.guid);
  }
}
</script>

<style>

</style>