<template>
<div v-if="Campagnematerialen" id="downs">
    <Intro 
        :btnBekijken="true"
        @bekijken="checkOpenen()"
        :imageGauche="true"
        :title="'Campagnematerialen'"
        :image="Campagnematerialen.actieS_AFBEELDINGTCAMPAGNEMATERIAAL"
        :info="Campagnematerialen.actieS_INFOTCAMPAGNEMATERIAAL"
        :aanvang="'Voor deze actie kan je van ' + Campagnematerialen.AANVANGTCAMPAGNEMATERIALEN + ' tot en met ' + Campagnematerialen.EINDETCAMPAGNEMATERIALEN + ' Campagnematerialen downloaden.'"
        :imageUrl="'SKWINKELNetActieCampagnematerialen'"
        :icon="'SKWINKELNetActieCampagnematerialenIcoontje'"
    />



    <section v-if="Campagnematerialen.campagneMaterialen && Campagnematerialen.campagneMaterialen.length > 0 && openen" id="Kpi">
      <div class="container">
        <div class="list-group list-group-flush">
          <div v-for="item in Campagnematerialen.campagneMaterialen" :key="item.campagnematerialeN_ID" class="list-group-item">
            <div class="row align-items-center">
              <div class="col">
                {{item.campagnematerialeN_BENAMING}}
              </div>
              <div class="col">
                {{item.campagnematerialeN_OMSCHRIJVING}}
              </div>
              <div class="col">
                {{item.campagnematerialeN_MEDIUM}}
              </div>
              <div class="col">
                {{item.campagnematerialeN_MATERIAAL}}
              </div>
              <div class="col">
                <button type="button" @click="Downloaden()" class="btn btn-primary">Downloaden</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-7">
        <button type="button" @click="openBekijken()" class="btn btn-primary">Sluiten</button>
      </div>
    </section>
</div>
</template>


<script>

import Intro from './IntroTables/Intro.vue'

export default {
  name: 'Campagnematerialen',
  data:function(){
    return{
      openen:false
    }
  },
  components:{
      Intro
  },
  props:{
      actieId: null
  },
  methods:{
    checkOpenen(){
      if(
        new Date(this.Campagnematerialen.actieS_AANVANGTCAMPAGNEMATERIALEN) <= Date.now() &&
        new Date(this.Campagnematerialen.actieS_EINDETCAMPAGNEMATERIALEN) >= Date.now())
        {
          this.openen = !this.openen

          if(!this.openen)
            this.scrollToTop();
        }
      else
        alert(new Date(this.Campagnematerialen.actieS_AANVANGTCAMPAGNEMATERIALEN) <= Date.now() ? 
        'De periode voor het downloaden campagnematerialen heeft geindigd.' :
        'De periode voor het downloaden campagnematerialen is nog niet begonnen.')    
    },
    scrollToTop(){
        const id = 'downs';
        const yOffset = -150; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    },
    openBekijken(){
      this.openen = !this.openen;

      if(!this.openen)
        this.scrollToTop();
    },
  },
  computed:{
    Campagnematerialen(){
      return this.$store.state.actieStore.Campagnematerialen ? this.$store.state.actieStore.Campagnematerialen.items : null;
    }
  },
  async created(){
    this.$store.dispatch('actieStore/getCampagnematerialen', this.actieId);
  }
}
</script>

<style scoped>

</style>