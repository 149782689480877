<template>
  <div v-if="actie">
    <BannerActie :actie="actie" :actieId="$route.params.id" />
  </div>
</template>

<script>
import BannerActie from '../../components/ActiePagina/BannerActie.vue'

export default {
  name: 'Actie',
  data:function(){
    return{
      color: 'red',
      colorLight : 'red'
    }
  },
  components: {
    BannerActie
  },
  computed:{
    actie(){
        return this.$store.state.actieStore.actie;
    }
  },
  async created(){
    await this.$store.dispatch('actieStore/getActie', this.$route.params.id);
  },
  watch:{
    actie(e){
      if(e)
        this.color = "#" + e.items.huisstijlkleureN_HEXACODE;
        this.colorLight = "#" + e.items.huisstijlkleureN_HEXACODE + 60;
    }
  }
}
</script>

<style>

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: v-bind('color') !important;
  border-color: v-bind('color') !important;
}

.btn-danger, .btn-danger:hover, .btn-danger:active, .btn-danger:visited {
  background-color: v-bind('color') !important;
  border-color: white !important;
  color:white !important;
}

.jauneBackground{
  background-color: v-bind('color') !important;
  border-color: v-bind('color') !important;
}

.colorJauneLight{
    color: v-bind('colorLight') !important;
}


.jauneBackgroundLight{
    background-color: v-bind('colorLight') !important;
    border-color: v-bind('colorLight') !important;
}

.colomKoppen{
  background-color: v-bind('colorLight') !important;
  color:black;
}

.colorJaune{
  color: v-bind('color') !important;
}

.list-group-item{
  padding: 15px 10px 10px 10px !important;
}

</style>