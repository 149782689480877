import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'

export const raadService = {
    getBestuurRaden,
    getLedenRaden,
    getAlgVerg
};

async function getBestuurRaden(){
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url()  + `/Raden/GetBestuurRaden`, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getLedenRaden(){
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url()  + `/Raden/GetLedenRaden`, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getAlgVerg(){
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url()  + `/Raden/GetAlgVergaderingen`, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}