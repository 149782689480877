import { actieService } from '../../services/Actie/actie.service'
import { getDate_ddmmyyyy } from '../../helpers/handleResponse';

export const actieStore = {
    namespaced: true,
    state: () => ({
        project: null,
        acties: null,
        actie: null,
        voorstellen:{items:null},
        evaluaties:{items:null},
        producten:{items:null},
        evaluatie:null,
        voorstel:null,
        registratie:null,
        Intro: null,
        Doelstellingen: null,
        VoorwaardenDeelnemers: null,
        VoorwaardenSponsors: null,
        ActiePlanning: null,
        CampagnePlanning: null,
        Sponsors: null,
        Deelnemers: null,
        Kpi: null,
        Campagnematerialen:null,
        productenSend:null,
        AllActies: null,
        AllActiesHome: null
    }),
    actions: {
        async getAllHomeActies({ commit }){
            await actieService.getAllHomeActies()
            .then(
                data => commit('getAllActiesHomeSuccess', data),
                error => commit('getAllActiesHomeFailure', error)
            )
        },

        async getAllActies({ commit }){
            await actieService.getAllActies()
            .then(
                data => commit('getAllActiesSuccess', data),
                error => commit('getAllActiesFailure', error)
            )
        },

        async getIntro({ commit }, id){
            await actieService.getIntro(id)
            .then(
                data => commit('getIntroSuccess', data),
                error => commit('getIntroFailure', error)
            )
        },
        
        async getDoelstellingen({ commit }, id){
            await actieService.getDoelstellingen(id)
            .then(
                data => commit('getDoelstellingenSuccess', data),
                error => commit('getDoelstellingenFailure', error)
            )
        },
        async getCampagnematerialen({ commit }, id){
            await actieService.getCampagnematerialen(id)
            .then(
                data => commit('getCampagnematerialenSuccess', data),
                error => commit('getCampagnematerialenFailure', error)
            )
        },
        async getVoorwaardenDeelnemers({ commit }, id){
            await actieService.getVoorwaardenDeelnemers(id)
            .then(
                data => commit('getVoorwaardenDeelnemersSuccess', data),
                error => commit('getVoorwaardenDeelnemersFailure', error)
            )
        },
        async getVoorwaardenSponsors({ commit }, id){
            await actieService.getVoorwaardenSponsors(id)
            .then(
                data => commit('getVoorwaardenSponsorsSuccess', data),
                error => commit('getVoorwaardenSponsorsFailure', error)
            )
        },
        async getActiePlanning({ commit }, id){
            await actieService.getActiePlanning(id)
            .then(
                data => commit('getActiePlanningSuccess', data),
                error => commit('getActiePlanningFailure', error)
            )
        },
        async getCampagnePlanning({ commit }, id){
            await actieService.getCampagnePlanning(id)
            .then(
                data => commit('getCampagnePlanningSuccess', data),
                error => commit('getCampagnePlanningFailure', error)
            )
        },
        async getSponsors({ commit }, id){
            await actieService.getSponsors(id)
            .then(
                data => commit('getSponsorsSuccess', data),
                error => commit('getSponsorsFailure', error)
            )
        },
        async getDeelnemers({ commit }, id){
            await actieService.getDeelnemers(id)
            .then(
                data => commit('getDeelnemersSuccess', data),
                error => commit('getDeelnemersFailure', error)
            )
        },
        async getKpi({ commit }, id){
            await actieService.getKpi(id)
            .then(
                data => commit('getKpiSuccess', data),
                error => commit('getKpiFailure', error)
            )
        },
        async sendVoorstel({commit}, voorstel){
            await actieService.sendVoorstel(voorstel)
            .then(error => commit('sendVoorstelError', error));
        },
        async sendProducten({commit}, res){
            await actieService.sendProducten(res)
            .then(error => commit('sendProductenError', error));
        },
        async sendRegistratieYes({commit}, res){
            await actieService.sendRegistratieYes(res)
            .then(error => commit('sendRegistratieError', error));
        },
        async sendRegistratieNo({commit}, res){
            await actieService.sendRegistratieNo(res)
            .then(error => commit('sendRegistratieError', error));
        },
        async getProject({ commit }, id){
            await actieService.getProject(id)
            .then(
                project => commit('getProjectSuccess', project),
                error => commit('getProjectFailure', error)
            )
        },
        async getVoorstellen({ commit }, id){
            await actieService.getVoorstellen(id)
            .then(
                acties => commit('getVoorstellenSuccess', acties),
                error => commit('getVoorstellenFailure', error)
            )
        },
        async sendEvaluatie({commit}, evaluatie){
            await actieService.sendEvaluatie(evaluatie)
            .then(error => commit('sendEvaluatieError', error));
        },
        async getEvaluaties({ commit }, id){
            await actieService.getEvaluaties(id)
            .then(
                acties => commit('getEvaluatiesSuccess', acties),
                error => commit('getEvaluatiesFailure', error)
            )
        },
        async getProducten({ commit }, id){
            await actieService.getProducten(id)
            .then(
                acties => commit('getProductenSuccess', acties),
                error => commit('getProductenFailure', error)
            )
        },
        async getActies({ commit }, id){
            await actieService.getActies(id)
            .then(
                acties => commit('getActiesSuccess', acties),
                error => commit('getActiesFailure', error)
            )
        },
        async getActie({commit}, id){
            await actieService.getActie(id)
            .then(
                actie => commit('getActieSuccess', actie),
                error => commit('getActieFailure', error)
            )
        }
    },
    mutations: {
        getAllActiesHomeSuccess(state, data){
            state.AllActiesHome = { items: data }
            
            if(state.AllActiesHome && state.AllActiesHome.items)
                state.AllActiesHome.items.forEach(element=>{
                    element.forEach(el=>{
                        if(el && el.actieS_AANVANGTACTIEPERIODE )
                            el.actieS_AANVANGTACTIEPERIODE = el.actieS_AANVANGTACTIEPERIODE ? getDate_ddmmyyyy(new Date(el.actieS_AANVANGTACTIEPERIODE)) :"";
                        if(el && el.actieS_EINDETACTIEPERIODE )
                            el.actieS_EINDETACTIEPERIODE = el.actieS_EINDETACTIEPERIODE ? getDate_ddmmyyyy(new Date(el.actieS_EINDETACTIEPERIODE)) : "";
                    });
                });
        },
        getAllActiesHomeFailure(state, error){
            state.AllActiesHome = error;
        },
        getAllActiesSuccess(state, data){
            state.AllActies = { items: data }

            var avant = [];
            var apres = [];

            if(state.AllActies && state.AllActies.items)
            {
                var index = 0;
                state.AllActies.items.forEach(element=>{
                    var av = true;
                    
                    element.imageGauche = index % 2 == 0;

                    if(new Date(element.actieS_AANVANGTACTIEPERIODE) >= Date.now())
                    {
                        av = false;
                    }

                    element.actieS_AANVANGTACTIEPERIODE = getDate_ddmmyyyy(new Date(element.actieS_AANVANGTACTIEPERIODE));
                    element.actieS_EINDETACTIEPERIODE = getDate_ddmmyyyy(new Date(element.actieS_EINDETACTIEPERIODE));

                    if(av)
                        avant.push(element);
                    else
                        apres.push(element);

                    index++;
                });
            }

            state.AllActies.itemsAvant = avant;
            state.AllActies.itemsApres = apres;
        },
        getAllActiesFailure(state, error){
            state.AllActies = error;
        },
        getIntroSuccess(state, data){
            
            state.Intro = { items: data };

            if(state.Intro.items)
            {
                if(state.Intro.items.actieS_AANVANGTACTIEPERIODE)
                    state.Intro.items.actieS_AANVANGTACTIEPERIODE = getDate_ddmmyyyy(new Date(state.Intro.items.actieS_AANVANGTACTIEPERIODE));
                
                if(state.Intro.items.actieS_EINDETACTIEPERIODE)
                    state.Intro.items.actieS_EINDETACTIEPERIODE = getDate_ddmmyyyy(new Date(state.Intro.items.actieS_EINDETACTIEPERIODE));
            }
        },
        getIntroFailure(state, error) {
            state.Intro = { error };
        },
        getCampagnematerialenSuccess(state, data){
            state.Campagnematerialen = { items: data }

            if(state.Campagnematerialen && state.Campagnematerialen.items)
            {
                if(state.Campagnematerialen.items.actieS_EINDETCAMPAGNEMATERIALEN)
                    state.Campagnematerialen.items.EINDETCAMPAGNEMATERIALEN = getDate_ddmmyyyy(new Date(state.Campagnematerialen.items.actieS_EINDETCAMPAGNEMATERIALEN));
                
                if(state.Campagnematerialen.items.actieS_AANVANGTCAMPAGNEMATERIALEN)
                    state.Campagnematerialen.items.AANVANGTCAMPAGNEMATERIALEN = getDate_ddmmyyyy(new Date(state.Campagnematerialen.items.actieS_AANVANGTCAMPAGNEMATERIALEN));
            }
        },
        getCampagnematerialenFailure(state, error) {
            state.Campagnematerialen = { error };
        },
        getDoelstellingenSuccess(state, data){
            state.Doelstellingen = { items: data }
        },
        getDoelstellingenFailure(state, error) {
            state.Doelstellingen = { error };
        },
        getVoorwaardenDeelnemersSuccess(state, data){
            state.VoorwaardenDeelnemers = { items: data }
        },
        getVoorwaardenDeelnemersFailure(state, error) {
            state.VoorwaardenDeelnemers = { error };
        },
        getVoorwaardenSponsorsSuccess(state, data){
            state.VoorwaardenSponsors = { items: data }
        },
        getVoorwaardenSponsorsFailure(state, error) {
            state.VoorwaardenSponsors = { error };
        },
        getActiePlanningSuccess(state, data){
            state.ActiePlanning = { items: data }

            if(state.ActiePlanning.items.planningen)
            {
                state.ActiePlanning.items.planningen.forEach(element => {
                    element.actieplanningeN_AANVANG = getDate_ddmmyyyy(new Date(element.actieplanningeN_AANVANG));
                    element.actieplanningeN_EINDE = getDate_ddmmyyyy(new Date(element.actieplanningeN_EINDE));
                });
            }
        },
        getActiePlanningFailure(state, error) {
            state.ActiePlanning = { error };
        },
        getCampagnePlanningSuccess(state, data){
            state.CampagnePlanning = { items: data }

            if(state.CampagnePlanning.items.campagnePlanningen)
            {
                state.CampagnePlanning.items.campagnePlanningen.forEach(element => {
                    element.campagneplanningeN_AANVANG = getDate_ddmmyyyy(new Date(element.campagneplanningeN_AANVANG));
                    element.campagneplanningeN_EINDE = getDate_ddmmyyyy(new Date(element.campagneplanningeN_EINDE));
                });
            }
        },
        getCampagnePlanningFailure(state, error) {
            state.CampagnePlanning = { error };
        },
        getSponsorsSuccess(state, data){
            state.Sponsors = { items: data }
        },
        getSponsorsFailure(state, error) {
            state.Sponsors = { error };
        },
        getDeelnemersSuccess(state, data){
            state.Deelnemers = { items: data }
        },
        getDeelnemersFailure(state, error) {
            state.Deelnemers = { error };
        },
        getKpiSuccess(state, data){
            state.Kpi = { items: data }
        },
        getKpiFailure(state, error) {
            state.Kpi = { error };
        },
        sendProductenError(state, error){
            state.productenSend = error;
        },
        sendRegistratieError(state, error){
            state.registratie = error;
        },
        sendVoorstelError(state, error){
            state.voorstel = error;
        },
        sendEvaluatieError(state, error){
            state.evaluatie = error;
        },
        getVoorstellenSuccess(state, project){
            state.voorstellen = { items: project };
        
            if(state.voorstellen.items)
            {
                if(state.voorstellen.items.actieS_EINDETACTIESUGGESTIES)
                    state.voorstellen.items.EINDETACTIESUGGESTIES = getDate_ddmmyyyy(new Date(state.voorstellen.items.actieS_EINDETACTIESUGGESTIES));
                if(state.voorstellen.items.actieS_AANVANGTACTIESUGGESTIES)
                    state.voorstellen.items.AANVANGTACTIESUGGESTIES = getDate_ddmmyyyy(new Date(state.voorstellen.items.actieS_AANVANGTACTIESUGGESTIES));
            }

            if(state.voorstellen && state.voorstellen.items.suggesties)
                state.voorstellen.items.suggesties.forEach(element => {
                    element.actiesuggestieS_UPDDAT = getDate_ddmmyyyy(new Date(element.actiesuggestieS_UPDDAT));
                    element.actiesuggestieS_AANGEMAAKT = getDate_ddmmyyyy(new Date(element.actiesuggestieS_AANGEMAAKT));

            });
        },

        getVoorstellenFailure(state, error) {
            state.voorstellen = { error };
        },
        getEvaluatiesSuccess(state, project){
            state.evaluaties = { items: project };
    
            if(state.evaluaties && state.evaluaties.items)
            {
                if(state.evaluaties.items.actieS_EINDETEVALUATIES)
                    state.evaluaties.items.EINDETEVALUATIES = getDate_ddmmyyyy(new Date(state.evaluaties.items.actieS_EINDETEVALUATIES));
                if(state.evaluaties.items.actieS_AANVANGTEVALUATIES)
                    state.evaluaties.items.AANVANGTEVALUATIES = getDate_ddmmyyyy(new Date(state.evaluaties.items.actieS_AANVANGTEVALUATIES));
            }

            if(state.evaluaties && state.evaluaties.items.evaluaties)
                state.evaluaties.items.evaluaties.forEach(element => {
                    element.evaluatieS_UPDDAT = getDate_ddmmyyyy(new Date(element.evaluatieS_UPDDAT));
                    element.evaluatieS_AANGEMAAKT = getDate_ddmmyyyy(new Date(element.evaluatieS_AANGEMAAKT));

                });
        },
        getEvaluatiesFailure(state, error) {
            state.evaluaties = { error };
        },
        getProductenSuccess(state, producten){
            state.producten = { items: producten };

            if(state.producten && state.producten.items)
            {
                if(state.producten.items.actieS_AANVANGTREGISTRATIES)
                    state.producten.items.AANVANGTREGISTRATIES = getDate_ddmmyyyy(new Date(state.producten.items.actieS_AANVANGTREGISTRATIES));
                
                if(state.producten.items.actieS_EINDETREGISTRATIES)
                    state.producten.items.EINDETREGISTRATIES = getDate_ddmmyyyy(new Date(state.producten.items.actieS_EINDETREGISTRATIES));
            }

            if(
                state.producten && state.producten.items && 
                state.producten.items.producten && 
                state.producten.items.producten.length > 0)
                {
                    state.producten.items.producten.forEach(element => {
                        element.totaalNetto = 0;
                        element.totaalBtw = 0;
                        element.ttc = 0;
                        element.aantal = 0;
                    });
                }
        },
        getProductenFailure(state, error) {
            state.producten = { error };
        },
        getProjectSuccess(state, project){
            state.project = { items: project };
        },
        getProjectFailure(state, error) {
            state.project = { error };
        },
        getActiesSuccess(state, acties){
            state.acties = { items: acties };
        },
        getActiesFailure(state, error) {
            state.acties = { error };
        },
        getActieSuccess(state, actie){
            state.actie = { items: actie };

            if(state.actie && state.actie.items)
            {
                if(state.actie.items)
                {
                    if(state.actie.items.actieS_AANVANGTACTIEPERIODE)
                        state.actie.items.actieS_AANVANGTACTIEPERIODE = getDate_ddmmyyyy(new Date(state.actie.items.actieS_AANVANGTACTIEPERIODE));
                    
                    if(state.actie.items.actieS_EINDETACTIEPERIODE)
                        state.actie.items.actieS_EINDETACTIEPERIODE= getDate_ddmmyyyy(new Date(state.actie.items.actieS_EINDETACTIEPERIODE));
                }

                if(state.actie.items.planningen)
                {
                    state.actie.items.planningen.forEach(element => {
                        element.actieplanningeN_AANVANG = getDate_ddmmyyyy(new Date(element.actieplanningeN_AANVANG));
                        element.actieplanningeN_EINDE = getDate_ddmmyyyy(new Date(element.actieplanningeN_EINDE));
                    });
                }

                if(state.actie.items.campagnePlanningen)
                {
                    state.actie.items.campagnePlanningen.forEach(element => {
                        element.campagneplanningeN_AANVANG = getDate_ddmmyyyy(new Date(element.campagneplanningeN_AANVANG));
                        element.campagneplanningeN_EINDE = getDate_ddmmyyyy(new Date(element.campagneplanningeN_EINDE));
                    });
                }
            }
        },
        getActieFailure(state, error) {
            state.actie = { error };
        }
    }
  }