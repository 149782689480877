<template v-if="pand">

  <img v-if="image" :class="images[0].length == 1 ? 'mb-7' : ''" :src="getImgUrl(image)" class="img-fluid"/>


  <div class="container">
    <div v-if="images[0].length > 1" id="carouselExampleIndicators" class="carousel slide" data-bs-interval="false">
      <div class="carousel-inner">
        <template v-for="(item, index1) in images" :key="index1">
          <div class="carousel-item" v-bind:class="{'active' : index1 == 0}">
            <div class="row">
              <div v-for="(item, index2) in item" :key="index2" class="col-3 d-flex flex-wrap align-items-center">
                <img :src="getImgUrl(item)" class="img-fuild mw-100 thumbNail" style="cursor: pointer;" @click="changer(index1, index2)"/>
              </div>
            </div>
          </div>
        </template>
      </div>
      <button v-if="images.length > 1" class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button v-if="images.length > 1" class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <h2 class="mb-3"><b>{{pand.bedrijfsruimteS_STRAATTNR}} {{pand.bedrijfsruimteS_PLAATSNAAM}}</b></h2>
    <p>{{pand.bedrijfsruimteS_INFO}}</p>
    <span v-if="pand.bedrijfsruimteS_STATUS" style="color: #1DAC00; text-transform: uppercase; font-size: 20px;">{{pand.bedrijfsruimteS_STATUS}}</span>

    <h3 class="mt-7 mb-3"><b>Contactgegevens</b></h3>
    <p v-if="pand.bedrijfsruimteS_CONTACTGEGEVENS">{{pand.bedrijfsruimteS_CONTACTGEGEVENS}}</p>

  </div>

  <!-- Form -->

  <div style="background-color:#b7c9e2; margin-top: 50px;">
    <transition tag="voors" name="voors">
    <div v-if="confirmMessage" class="text-center" style="padding-top: 50px; padding-bottom: 50px;">
        Bedankt, voor uw voorstel, u zult binnenkort een bevestigingesmail krijgen.
    </div>
    </transition>
    <transition tag="voors" name="voors">
    <template v-if="!confirmMessage">
      <div class="container pt-5 pb-5">
        <h2 class="mb-5"><b>Jouw suggestie</b></h2>
        <p class="mb-5">Ben je inwoner van onze SKWinkelende gemeente of doe je er regelmatig je inkopen stuur dan jouw suggesties in voor de invulling deze bedrijfsruimtes. Wie weet zit er dan binnenkort wel een SKWinkelbon in jouw virtuele brievenbus!</p>
        <input 
          type="text" 
          placeholder="Voornaam"
          class="form-control mb-5"
          v-model="voornaam"
          :style="{'border': voornaamRempli ? '1px solid white' : '2px solid red',}">
        <input 
          type="text" 
          placeholder="Naam"
          class="form-control mb-5"
          v-model="naam"
          :style="{'border': naamRempli ? '1px solid white' : '2px solid red',}">
        <input 
          type="text" 
          placeholder="E-mailadres"
          class="form-control mb-5"
          v-model="email"
          :style="{'border': emailRempli ? '1px solid white' : '2px solid red',}">
        <input 
          type="text" 
          placeholder="Mobiel nummer"
          class="form-control mb-5"
          v-model="mobile"
          :style="{'border': mobileRempli ? '1px solid white' : '2px solid red',}">
        <textarea 
          v-model="suggestie"
          class="form-control mb-5"
          placeholder="Jouw suggestie"
          :style="{'border': suggestieRempli ? '1px solid white' : '2px solid red',}">
        </textarea>
        <div v-if="errorMsg" style="color: red; float: right;">{{ errorMsg }}</div>
        <button type="button" @click="insturen" class="btn btn-dark">Verstuur</button>
        <div style="clear: both;"></div>
      </div>
    </template>
    </transition>
  </div>

  <!--Back button-->

  <div class="container">
    <router-link to="/" class="btn btn-dark mt-10 mb-10" style="width:100%;font-size: 15px; font-weight: 500;" role="button">
      TERUG NAAR OVERZICHT BEDRIJFSRUIMTES
    </router-link>  
  </div>
  
  <Footer></Footer>

</template>

<script>
import Footer from '../PandenPagina/Footer.vue'

export default {
  name: 'BannerPandOne',
  props:{
    pand: null,
  },
  components:{
    Footer
  },
  data: function(){
    return{
      images:[],
      image:"",

      voornaam: null,
      naam: null,
      email: null,
      mobile:null,
      suggestie:null,

      voornaamRempli: true,
      naamRempli: true,
      emailRempli: true,
      mobileRempli:true,
      suggestieRempli:true,

      errorMsg:null,
      confirmMessage: false,
    }
  },
  methods:{
    getImgUrl(pet) {
      return require('../../../assets/Panden/'+ pet)
    },
    changer(x, y){
      this.image = this.images[x][y];
    },
    insturen(){
      this.errorMsg = null;
      let rempli = true;

      rempli = this.voornaamRempli = !(this.voornaam == null || this.voornaam == "");
      rempli = this.naamRempli = !(this.naam == null || this.naam == "");
      rempli = this.emailRempli = !(this.email == null || this.email == "");
      rempli = this.mobileRempli = !(this.mobile == null || this.mobile == "");
      rempli = this.suggestieRempli = !(this.suggestie == null || this.suggestie == "");

      if(!rempli)
      {
        this.errorMsg = "Alle velden moeten ingevuld worden.";
        return;
      }

      if(!this.validateEmail(this.email))
      {
        this.emailRempli = false;
        this.errorMsg = "Gelieve een geldige emailadres in te vullen";
        return;
      }

      this.emailRempli = true;

      var res = {
        "Voornaam": this.voornaam,
        "Naam": this.naam,
        "Email":this.email,
        "Mobile": this.mobile,
        "Suggestie": this.suggestie,
        "BedrijfsRuimte": this.pand.bedrijfsruimteS_AIRID
      }

      this.$store.dispatch('pandenStore/sendVoorstel', res);
      this.confirmMessage = true;
    },
    validateEmail(email){
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }
  },
  created(){
    if(this.pand.bedrijfsruimteS_AFBEELDINGEN && this.pand.bedrijfsruimteS_AFBEELDINGEN.split(";").length > 0)
    {
      var arr = this.pand.bedrijfsruimteS_AFBEELDINGEN.split(";");      

      for(let i = 0; i < arr.length; i += 4)
        this.images.push(arr.slice(i,i+4));

      this.image = this.images[0][0];
    }
 }
}

</script>

<style scoped>
.row{
  padding-right: 55px !important;
  padding-left: 55px !important;
}
.img-fluid {
  width: 100%;
}
.carousel-control-next{
  width:50px !important;
}

.carousel-control-prev{
  width:50px !important;
}
.carousel-control-prev-icon, .carousel-control-next-icon{
  padding-top: 9px;
} 
.carousel{
  padding-top:55px !important;
  padding-bottom:55px !important;
  padding-left:0px !important;
  padding-right:0px !important;
}

.voors-enter-from,.voors-leave-to{
  opacity:0;
  height: 0%;
}

.voors-enter-to,.voors-leave-from{
  opacity:1;
  height: 100%;
}

.voors-enter-active, .voors-leave-active{
  transition:all 0.4s ease-out;
}

.btn{
  border-radius: 5px !important;
  background-color: #22647A;
  border-color: #22647A;
  color: white !important;
}

.btn:hover{
  background-color: #154150;
  border-color: #154150;
}

/* .row > * {
  padding-left: 10px;
  padding-right: 10px;
} */
</style>