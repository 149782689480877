import {getDate_forApi} from '../helpers/handleResponse'

export function parseModel(obj){
    var array = [];
    var placeholders = null;
    var disabled = null;
    var types = null;
    var dropDowns = null;

    for(const prop in obj)
    {       
        if(prop == "tumiPlaceholders"){
            placeholders = obj[prop]; 
        }
        else if(prop == "tumiDisabled"){
            disabled = obj[prop];
        }
        else if(prop == "tumiTypes"){
            types = obj[prop];
        }
        else if(prop == "tumiDropDowns"){
            dropDowns = obj[prop];
        }
        else{
            if(prop != "tumiTypes")
            {
                if(prop.split('_')[1] != "ID")
                    array.push(
                        {
                            name: prop,
                            placeholder: FirstLetterToUpper(prop),
                            class: "form-control mb-5 " + prop,
                            divClass:"div" + prop,
                            value: obj[prop],
                            disabled: false,
                            type : null,
                            options: null
                        }
                    )
            }
        }       
    }
    
    if(types != null)
        for(const nom in types)
            array.find(i => i.name.toLowerCase() == nom.toLowerCase()).type = types[nom];

    if(placeholders != null)
        for(const nom in placeholders)
            array.find(i => i.name.toLowerCase() == nom.toLowerCase()).placeholder = placeholders[nom];


    if(disabled != null)
        for(const nom in disabled)
        {
            array.find(i => i.name.toLowerCase() == disabled[nom].toLowerCase()).class += " TumiDisabled";
            array.find(i => i.name.toLowerCase() == disabled[nom].toLowerCase()).disabled = true;
        }

    if(dropDowns != null)
        for(const nom in dropDowns)
            array.find(i => i.name.toLowerCase() == nom.toLowerCase()).options = dropDowns[nom];

    return array;
}

export function GetFromView(viewRefs, obj, inputs){
    const objCopy = obj;
    var types = null;

    for(const prop in objCopy)
        if(prop == "tumiTypes")
            types = objCopy[prop];

    for(const prop in objCopy)
        try{
            if(prop != "tumiPlaceholders" && prop != "tumiTypes" && prop.split('_')[1] != "ID" && prop != "tumiDisabled" && prop != "tumiDropDowns")
            {
                if(types[prop.toUpperCase()]){
                    if(types[prop.toUpperCase()] == "Date")
                    {
                        if(inputs.find(i=>i.name == prop).value){
                            var date = inputs.find(i=>i.name == prop).value
                            obj[prop] = getDate_forApi(new Date(date));
                        }
                        else{
                            obj[prop] = null;
                        }
                    }

                    if(types[prop.toUpperCase()] == "DropDown")
                    {
                        if(inputs.find(i=>i.name == prop).value){
                            obj[prop] = inputs.find(i=>i.name == prop).value;
                        }
                        else{
                            obj[prop] = null;
                        }
                    }

                }
                else{
                    obj[prop] = viewRefs[prop][0].input.value;
                }
            }
        }
        catch(ex){
            console.log(ex);
        }

    return obj;
}

function FirstLetterToUpper(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// function ToUpper(obj){
//     if (typeof obj !== 'object') {
//         return obj;
//     }
//     if (Array.isArray(obj)) {
//         return obj.map(ToUpper);
//     }
//     if (obj === null) {
//         return null;
//     }

//     const entries = Object.entries(obj);
//     const mappedEntries = entries.map(
//         ([k, v]) => [k.toUpperCase(), v]
//     );

//     return Object.fromEntries(mappedEntries);
// }