import { raadService } from '../../services/Raad/raad.service'

export const raadStore = {
    namespaced: true,
    state: () => ({
        raden: {},
        ledenRaden: {},
        algVerg: {},
    }),
    actions: {
        async getBestuurRaden({commit}){
            await raadService.getBestuurRaden()
            .then(
                data => commit('getBestuurRadenSuccess', data),
                error => commit('getBestuurRadenFailure', error)
            )
        },
        async getLedenRaden({commit}){
            await raadService.getLedenRaden()
            .then(
                data => commit('getLedenRadenSuccess', data),
                error => commit('getLedenRadenFailure', error)
            )
        },
        async getAlgVerg({commit}){
            await raadService.getAlgVerg()
            .then(
                data => commit('getAlgVergSuccess', data),
                error => commit('getAlgVergFailure', error)
            )
        }
    },
    mutations: {
        getBestuurRadenSuccess(state, data){
            state.raden = data;
        },
        getBestuurRadenFailure(state, error) {
            state.raden = error;
        },
        getLedenRadenSuccess(state, data){
            state.ledenRaden = data;
        },
        getLedenRadenFailure(state, error) {
            state.ledenRaden = error;
        },
        getAlgVergSuccess(state, data){
            state.algVerg = data;
        },
        getAlgVergFailure(state, error) {
            state.algVerg = error;
        }
    }
}