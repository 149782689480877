<template>
    <div class="row align-items-center">
      <div class="col-2">
        <img v-if="logo" class="imgTumi" :src="JSON.parse(logo)[0]"/>
      </div>
      <div class="col-10">
        <h3 class="mb-3">{{naam}}</h3>
        <h4 class="mb-1">{{adres}}</h4>

        <div class="clearfix"></div>
        <!-- Text -->
        <small class="text-gray-700">
            <a v-if="email" :href="'mailto:'+ email">{{email}}</a>
            <span v-if="tel && email"> - </span><a v-if="tel" :href="'tel:' + tel">{{tel}}</a> 
            <span v-if="mobiel && (tel || email)"> - </span><a  v-if="mobiel" :href="'tel:' + mobiel">{{mobiel}}</a> 
            <a v-if="web" :href="'http://' + web" target="_blank"><img src="@/assets/www.png" alt="web" /></a>
            <a v-if="Facebook" :href="Facebook" target="_blank"><img src="@/assets/FB.png" alt="facebook" /></a>
            <a v-if="Insta" :href="Insta" target="_blank"><img src="@/assets/Insta.png" alt="instagram" /></a>
        </small>
    </div>
    </div>
</template>

<script>
export default {
  name: 'Organisatie',
  props:{
      naam:null,
      adres:null,
      email:null,
      tel:null,
      mobiel:null,
      web:null,
      Facebook:null,
      Insta:null,
      logo:null
  },
}
</script>

<style scoped>
img{
  margin-bottom:4px;
  height:30px;
  margin-left:10px;
}

.imgTumi{
  height:100px;
}

a{
  color:black;
}
</style>