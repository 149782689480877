<template>
    <section v-if="actie" id="Actiesuggesties">
    <div class="container text-center mt-10 mb-7">
        <div class="row text-md-start aos-init aos-animate mt-5 align-items-center" data-aos="fade-left">
            <div v-if="imageGauche" class="col-5">
                <img :src="require('@/assets/Actie/' + imageUrl + actie.huisstijlkleureN_HEXACODE + '.png')" class="img-fluid mw-100" alt="beeld Omschrijving">
            </div>
            <div :class="{'col-7':true, 'ps-10':imageGauche, 'pe-10':!imageGauche}">
                <img :src="require('@/assets/Actie/' + icon + 'Zwart.png')" class="logoZwart mt-n2 me-2 img-fluid mw-100 float-start me-4" alt="beeld Omschrijving">
                <h2 class="mb-3 titreTumi">
                    {{title}}
                </h2>
                <div class="clearfix"></div>
                {{info}}
                <p v-if="aanvang" class="mt-3 text-muted">
                    {{aanvang}}
                </p>

                <p v-if="btnNiet" class="textCancel">
                    Wens je niet deel te nemen klik dan op de rode button en laat even weten waarom niet. Jouw feedback is immers zeer waardevol.
                </p>

                <div v-if="moyenne" class="mt-3 mb-10">
                    <img v-for="item in moyenne" :key="item" class="star" src="@/assets/StarOn.png" />
                    <img v-for="item in 5 - moyenne" :key="item" class="star" src="@/assets/StarOff.png" />
                    (gemiddeld aantal sterren op basis van {{nombre}} evaluaties)
                </div>



                <div class="row mt-5">
                    <div class="col">
                        <div v-if="btnBekijken" class="float-start me-5">
                            <button type="button" class="btn btn-primary" @click="$emit('bekijken')" v-if="!nomBekijken">{{endNumbre}}
                                <img :src="require('@/assets/Actie/' + icon + 'Wit.png')" class="img-fluid" width="30" alt="beeld Omschrijving"> bekijken
                            </button>
                            <button type="button" class="btn btn-primary" @click="$emit('bekijken')" v-if="nomBekijken"><img :src="require('@/assets/Actie/' + icon + 'Wit.png')" class="img-fluid" alt="beeld Omschrijving"> {{nomBekijken}}</button>
                        </div>

                        <div v-if="btnDoen" class="float-start me-5">
                            <button :style="{'background-color':'#' + actie.huisstijlkleureN_HEXACODE, 'border-color':'#' + actie.huisstijlkleureN_HEXACODE}" type="button" class="btn btn-secondary" @click="$emit('doen')"><img :src="require('@/assets/Actie/' + icon + 'Wit.png')" class="img-fluid" width="30" alt="beeld Omschrijving"> Insturen</button>
                        </div>
                        <div v-if="btnNiet">
                            <button type="button" class="btn btn-secondary btnCancel" @click="$emit('nietdoen')">Niet <img :src="require('@/assets/Actie/' + icon + 'Wit.png')" :class="cacherNietDoen ? 'img-fluid' : 'img-fluid desact'" width="30" alt="beeld Omschrijving"></button>
                        </div>
                    </div>
                </div>  
                
                <div class="row mt-3">

                </div>  
            </div>
            <div v-if="!imageGauche" class="col-5">
                <img :src="require('@/assets/Actie/' + imageUrl + actie.huisstijlkleureN_HEXACODE + '.png')" class="img-fluid mw-100" alt="beeld Omschrijving">
            </div>
        </div>
    </div>
    </section>
</template>

<script>
export default {
  name: 'Intro',
  props:{
      cacherNietDoen:null,
      imageGauche:null,
      imageUrl:null,
      nomDoen:null,
      nomBekijken:null,
      title: null,
      info: null,
      endNumbre :null,
      image:null,
      aanvang:null,
      btnBekijken:null,
      btnDoen:null,
      icon:null,
      moyenne:null,
      nombre:null,
      btnNiet:null,
  },
  computed:{
    actie(){
        return this.$store.state.actieStore.actie.items;
    }
  }
}
</script>

<style scoped>
h2{
    color:black;
}

.star{
    width:25px;
}

.logoZwart{
    height:35px;
}

.btnCancel{
    background-color: #cc4141;
    border-color:#cc4141;
}

.textCancel{
    color:#cc4141;
}

.desact{
    pointer-events: none;
}
</style>