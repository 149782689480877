<template>
<div v-if="evaluaties" id="evas">
  <Intro
    :btnBekijken="true"
    :btnDoen="true"
    @bekijken="bekijkenOpenen()"
    @doen="checkOpenen()"
    :imageGauche="true"
    :title="'Evaluaties'"
    :info="evaluaties.actieS_INFOTEVALUATIES"
    :endNumbre="evaluaties && evaluaties.evaluaties ? evaluaties.evaluaties.length : null"
    :image="evaluaties.actieS_AFBEELDINGTEVALUATIES"
    :aanvang="'Voor deze actie kan je van ' + evaluaties.AANVANGTEVALUATIES + ' tot en met ' + evaluaties.EINDETEVALUATIES + ' jouw evaluatie insturen.'"
    :moyenne="moyenne"
    :nombre="evaluaties && evaluaties.evaluaties ? evaluaties.evaluaties.length : 0"
    :imageUrl="'SKWINKELNetActieEvaluaties'"
    :icon="'SKWINKELNetActieEvaluatiesIcoontje'"
    />

  <EvaluatiePopup 
    v-if="openEvaluatie" 
    :actieId="actieId" 
    :actie="evaluaties" 
    :organisatie="log.organisatie"
    @clicked="updateSuggesties" @sluiten="checkOpenen()" />

  <section v-if="evaluaties && evaluaties.evaluaties && evaluaties.evaluaties.length > 0 && openen">
    <div class="container">
      <h3 class="mt-7">Voor deze actie werden {{evaluaties.evaluaties.length}} evaluaties ingestuurd.</h3>
      <div class="list-group list-group-flush">
          <div v-for="evaluatie in aantal" :key="evaluatie" class="list-group-item">
            <div class="row align-items-center">
                  <div class="col-2">
                    <span v-if="evaluaties.evaluaties[evaluatie - 1].evaluatieS_AANGEMAAKT" class="float-start mb-1 me-5">{{evaluaties.evaluaties[evaluatie - 1].evaluatieS_AANGEMAAKT}}</span>
                    <span v-if="!evaluaties.evaluaties[evaluatie - 1].evaluatieS_AANGEMAAKT" class="float-start mb-1 me-5">{{evaluaties.evaluaties[evaluatie - 1].evaluatieS_UPDDAT}}</span>
                  </div>
                  <div class="col-3">
                    <div class="mt-n3">
                      <img v-for="item in evaluaties.evaluaties[evaluatie - 1].evaluatieS_BEOORDELING" :key="item" src="@/assets/StarOn.png" />
                      <img v-for="item in 5 - evaluaties.evaluaties[evaluatie - 1].evaluatieS_BEOORDELING" :key="item" src="@/assets/StarOff.png" />
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-4">
                    <!-- Heading -->
                    <span class="mb-2">{{evaluaties.evaluaties[evaluatie - 1].evaluatieS_ORGANISATIE}}</span>
                  </div>
                  <div class="col">
                    <span>
                      {{evaluaties.evaluaties[evaluatie - 1].evaluatieS_OMSCHRIJVING}}
                    </span><br/>
                  </div>
 
            </div>
          </div>
      </div>

      <div class="text-center mt-7">
        <button v-if="aantal != evaluaties.evaluaties.length" type="button" @click="showMore()" class="btn btn-primary">meer evaluaties tonen</button>
      </div>
      <div class="text-center mt-7">
        <button type="button" @click="bekijkenOpenen()" class="btn btn-primary">Sluiten</button>
      </div>
    </div>
  </section>



</div>
</template>


<script>
import Intro from './IntroTables/Intro.vue'
import EvaluatiePopup from './Popups/EvaluatiePopup.vue'
import {delay} from './../../helpers/handleResponse.js'

export default {
  name: 'ActieVoorstellen',
  components:{
    Intro,
    EvaluatiePopup
  },
  data:function(){
    return{
      openen: false,
      openEvaluatie: false,
      modal: false,
      aantal : 0,
      clicked : 0,
      moyenne: 5
    }
  },
  props:{
      actieId: null,
  },
  methods:{
    openForm(){
        this.openen = false;
        this.openEvaluatie = true;
        this.scrollToBottom();
    },
    openList(){
        this.openEvaluatie = false;
        this.openen = true;
        this.scrollToBottom();
    },
    async checkOpenen(){
      if(new Date(this.evaluaties.actieS_AANVANGTEVALUATIES) <= Date.now() && new Date(this.evaluaties.actieS_EINDETEVALUATIES) >= Date.now())
      {
        if(this.log)
        {
          if(this.openEvaluatie)
          {
            this.scrollToTop();
            await delay(0.8);
            this.openEvaluatie = false;
          }
          else
            this.openForm();
        }
        else
          this.$router.push('/account')

      }
      else
        alert(new Date(this.evaluaties.actieS_AANVANGTEVALUATIES) <= Date.now() ? 
        'Je kan geen evaluaties meer insturen voor de deze actie.' :
        'Je kan nog geen evaluaties insturen voor deze actie.')
    },
    async updateSuggesties(){
      this.$store.dispatch('actieStore/getEvaluaties', this.actieId);
      this.scrollToTop();  
      await delay(0.8);
      this.openEvaluatie = false;
    },
    async onClickPopup(){
      this.modal = false;
      this.$store.dispatch('actieStore/getEvaluaties', this.actieId);
    },
    showMore(){
      this.clicked++;
      this.update();
    },
    update(){
      if(!this.evaluaties || !this.evaluaties.evaluaties || this.evaluaties.evaluaties.length == 0)
        this.aantal = 0;
      else
      {
        this.moyenne = Math.round(
            this.evaluaties.evaluaties.reduce(function(a,b){return a + b['evaluatieS_BEOORDELING']},0) / 
            this.evaluaties.evaluaties.length
          );

        var enPlus = 5 * this.clicked;
        var afficher = 5 + enPlus;
        this.aantal = this.evaluaties.evaluaties.length < afficher ? this.evaluaties.evaluaties.length : afficher;
      }
    },
    scrollToTop(){
        const id = 'evas';
        const yOffset = -410; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    },
    async scrollToBottom(){
        const id = 'evas';
        const yOffset = 500; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        await delay(0.6);

        window.scrollTo({top: y, behavior: 'smooth'});
    },
    async bekijkenOpenen(){
      if(this.openen)
      {
        this.scrollToTop();
        await delay(0.6);
        this.openen = !this.openen;
      }
      else
        this.openList();
    },
  },
  watch:{
    evaluaties(){
      this.update();
    }
  },
  computed:{
    evaluaties(){
      return this.$store.state.actieStore.evaluaties ? this.$store.state.actieStore.evaluaties.items : null;
    },
    log(){
      return this.$store.getters['authenticationStore/isLoggedIn'];
    }    
  },
  async created(){
    this.$store.dispatch('actieStore/getEvaluaties', this.actieId);
    console.log(this.$store);
  },
}

</script>

<style scoped>
img{
  width:25px;
  height: 25px;;
}

li{
  text-decoration: none;
}

ul{
  list-style-type: none;
  margin:0;
}

h1{
    font-weight: 900;
}

.titre{
  color:#5ca2be;
}

.list-enter-from{
  opacity:0;
  transform:scale(0);
}

.list-enter-to{
  opacity:1;
  transform:scale(1);
}

.list-enter-active{
  transition:all 0.4s ease;
}

.voors-enter-from,.voors-leave-to{
  opacity:0;
}

.voors-enter-to,.voors-leave-from{
  opacity:1;
}

.voors-enter-active, .voors-leave-active{
  transition:all 0.4s ease-out;
}
</style>