<template>
  <div class="col-12 col-md-2 position-md-sticky align-items-center vh-md-90 mx-auto" style="top: 0;">
    <!-- Sidebar -->
    <aside class="flex-grow-1 py-8 py-md-10">

      <!-- Brand -->
      <h2>{{name}}</h2>

      <!-- List group -->
      <ul class="list-group list-group-flush">
        <li 
          v-for="path in paths" 
          :key="path.name" 
          :class="{'list-group-item pointerTumi':'true','TumiSel': selected == path.name}" 
          @click="Click(path.name)">
          
          <h3 class="d-flex mb-0">
            {{path.name}}
          </h3>
        
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props:{
    name:null,
    paths:null
  },
  data:function(){
    return{
      selected:null
    }
  },
  methods:{
    Click(name){
      this.$emit('switch', name);
      this.selected = name;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.pointerTumi{
  cursor: pointer;
}

.list-group-item:hover{
  background-color: rgb(213, 211, 211);
}

.list-group-item:active{
  background-color: rgb(213, 211, 211);
}

.TumiSel{
  background-color: rgb(213, 211, 211);
}

</style>
