<template>
  <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-interval="3000" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div v-for="index in 16" :key="index" :class="index == 1 ? 'carousel-item active' : 'carousel-item'">
      <img class="d-block w-100" :src="getImgUrl(index + '.png')" alt="First slide">
    </div>
  </div>
</div>

  <!-- <img :src="getImgUrl(imageNumber + '.png')" class="img-fluid" :key="imageNumber + '.png'"/> -->

  <div class="container">
    <div class="row">
      <h1 class="fw-bold mb-5 mt-10"> Start jouw SKWINKEL hier, waar anders!?</h1>
      <p class="lead text-md-start text-muted fontSpecial">
        “Vol van leven”. Dat is de slogan die onder het logo van Sint-Katelijne-Waver staat te lezen. Een verwijzing naar een dynamische gemeente waar het goed is om te leven, te werken én te ondernemen. Heb jij wat dat laatste betreft ideeën en/of plannen en ben je op zoek naar een geschikte locatie dan vind je in Sint-Katelijne-Waver ongetwijfeld de ideale bedrijfsruimte. Bekijk hier het beschikbare aanbod en de respectievelijke info en gegevens en wie weet maakt jouw zaak binnenkort deel uit van onze SKWinkelende gemeente.</p>
      <h1 class="fw-bold mb-5 mt-7">Jouw favoriete SKWinkel hier, waar anders!?</h1>
      <p class="lead text-md-start text-muted fontSpecial">
        Ben je inwoner van onze SKWinkelende gemeente of doe je er regelmatig je inkopen dan kijken wij uit naar jouw suggesties voor de invulling van de beschikbare bedrijfsruimtes. Klik op een bedrijfsruimte en stuur jouw suggestie in, wie weet zit er dan binnenkort wel een SKWinkelbon in jouw virtuele brievenbus!</p>
    </div>
  </div>
  <div v-if="panden && panden.items">
    <div v-for="(item, index) in panden.items" :key="item">
      <PandM :pand="item" :index="index"/>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import PandM from './PandM.vue'
import Footer from './Footer.vue'

export default {
  name: 'BannerPanden',
  props:{
    panden: null,
  },
  components:{
    PandM,
    Footer
  },
  data: function(){
    return{
      index:1,
      imageNumber:0,
    }
  },
  methods:{
    getImgUrl(pet) {
      return require('../../../assets/Panden/'+ pet)
    },
    getRandom(){
      let newRandom = Math.floor(Math.random() * (16 - 1 + 1)) + 1;
      while(newRandom == this.imageNumber)
      {
        newRandom = Math.floor(Math.random() * (16 - 1 + 1)) + 1;
      }

      return newRandom;
    }
  },
  created(){
    this.imageNumber = this.getRandom();

    setInterval(()=> this.imageNumber = this.getRandom(), 3000);
  }
}
</script>

<style scoped>
.carousel{
  padding:0px !important;
}
</style>