<template>
  <div>
    <ActieModule 
      v-for="actie in AllActiesApres" 
      :key="actie.actieS_ID" 
      :actie="actie" 
      :imageGauche="actie.imageGauche"/>

<div class="container">
  <div class="badge rounded-pill bg-success pt-2 pb-2 pe-6 ps-6">
    Nu
  </div>
  <div class="nuTumi">
  </div>
  <div class="clearfix"></div>
</div>
    <ActieModule 
      v-for="actie in AllActiesAvant" 
      :key="actie.actieS_ID" 
      :actie="actie" 
      :imageGauche="actie.imageGauche"/>
    


  </div>
</template>


<script>
import ActieModule from './ActieModule.vue'

export default {
  name: 'AllActiesPagina',
  props:{
    AllActiesAvant: null,
    AllActiesApres: null
  },
  components:{
    ActieModule
  }
}
</script>

<style scoped>

.badge{
  font-size: 16px;
  font-weight: 100;
}

.nuTumi{
  background-color: #42BA96;
  height: 2px;
  border-radius: 1px;
  margin-top:-16px;
}
</style>