<template>
<div>
  <IntroStart />
  <BannerPandenHome />
  <StartPanden v-if="panden && panden.items" :panden="panden.items"/>
</div>
</template>

<script>
import BannerPandenHome from '../../components/Home/BannerPandenHome.vue'
import StartPanden from '../../components/Start/StartPanden.vue'
import IntroStart from '../../components/Start/IntroStart.vue'

export default {
  name: 'Start',
  components:{
    BannerPandenHome,
    StartPanden,
    IntroStart
  },
  computed:{
    panden(){
        return this.$store.state.pandenStore.panden;
    }
  },
  async created(){
    await this.$store.dispatch('pandenStore/getPanden');
  },
}
</script>

<style scoped lang="scss">

</style>