import { userService } from "../services/User/user.service";

export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function divisionEnChunk(liste, nombre){
    var zones = [];

    for (let i = 0; i < liste.length; i += nombre) {
        zones.push(liste.slice(i, i + nombre));
    }

    return zones;
}

export function getDate_ddmmyyyy(date) {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2,'0');
    const dd = String(date.getDate()).padStart(2,'0');

    return `${dd}/${mm}/${yyyy}`
}

export function getDate_forApi(date) {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2,'0');
    const dd = String(date.getDate()).padStart(2,'0');

    return `${yyyy}-${mm}-${dd}`
}


export function shadeColor(color, percent) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

export function delay(n){
    return new Promise(function(resolve){
        setTimeout(resolve,n*1000);
    });
}

export function handleResponse(response) {
    return response.text().then(text => {
        if (!response.ok) {
            if (response.status === 401) {
                userService.logout();
                location.reload(true);
            }

            //If 400 bad request we get an object with a message
            if(response.status === 400)
            {
                return Promise.reject(JSON.parse(text).message);
            }

            //If 500 intern error we don't show the message (could have sensible info)
            if(response.status === 500)
            {
                //Do log something
                //....
                return Promise.reject("An error occured.");
            }

            return Promise.reject("Something unusual happened");
        }
        else
        {
            return text && JSON.parse(text);
        }
    });
}