import { dynamicService } from '../../services/Dynamic/dynamic.service'

export const dynamicStore = {
    namespaced: true,
    state: () => ({
    }),
    actions: {
        deleteSolidState({commit}, stateName){
            commit('deleteSolidState', stateName);
        },
        async getDynamic({ commit }, path){
            await dynamicService.getDynamic(path)
            .then(
                data => commit('getDynamicSuccess', {data, path}),
                error => commit('getDynamicFailure', {error, path})
            );   
        },
        async getDynamicSolidState({ commit }, {path, stateName}){
            await dynamicService.getDynamic(path)
            .then(
                data => commit('getDynamicSolidStateSuccess', {data, stateName}),
                error => commit('getDynamicSolidStateFailure', {error, stateName})
            );   
        },
        async setDynamic({ commit }, {pathSet, retour}){
            await dynamicService.setDynamic(pathSet, retour)
            .then(
                data => commit('setDynamicSuccess', {data, pathSet}),
                error => commit('setDynamicFailure', {error, pathSet})
            ); 
        },
    },
    mutations: {
        deleteSolidState(state, stateName){
            state[stateName] = null;
        },
        getDynamicSuccess(state, {data, path}){
            state[path] = data ;
        },
        getDynamicFailure(state, {error, path}) {
            state[path] = error;
        },
        setDynamicSuccess(state, {data, path}){
            state[path] = data ;
        },
        setDynamicFailure(state, {error, path}) {
            state[path] = error;
        },
        getDynamicSolidStateSuccess(state, {data, stateName}){
            state[stateName] = data ;
        },
        getDynamicSolidStateFailure(state, {error, stateName}) {
            state[stateName] = error;
        },
    }
}