<template>
    <section v-if="Intro" class="mt-10 text-center" id="Info">
        <div class="container">
            <img v-if="Intro.actieS_THEMABEELDENT4160TT1980" :src="JSON.parse(Intro.actieS_THEMABEELDENT4160TT1980)[2].url" alt="campagnebeeld" class="img-fluid"/>
        </div>
        <h2 class="display-3 fw-bold mt-7">
            {{Intro.actieS_BENAMING}}            
        </h2>
        <h2 class="fw-bold mt-1">
            {{Intro.actieS_AANVANGTACTIEPERIODE}} - {{Intro.actieS_EINDETACTIEPERIODE}}
        </h2>
    </section>
    <TestIntro v-if="Intro"
        :title="'Draaiboek'"
        :image="Intro.actieS_AFBEELDINGTOMSCHRIJVING"
        :info="Intro.actieS_OMSCHRIJVING"
        :imageUrl="'SKWINKELNetActieDraaiboek'"
        :icon="'SKWINKELNetActieDraaiboekIcoontje'"
    />
</template>

<script>
import TestIntro from './IntroTables/Intro.vue'

export default {
    name: 'Intro',
    props:{ actieId: null },
    components:{
        TestIntro
    },
    computed:{
        Intro(){
            return this.$store.state.actieStore.Intro ? this.$store.state.actieStore.Intro.items : null;
        }
    },
    async created(){
        this.$store.dispatch('actieStore/getIntro', this.actieId);
    }
}
</script>

<style scoped>

</style>