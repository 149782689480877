<template>
    <div :class="input.divClass">
      <select v-model="input.value" class="form-control">
        <option value="">Please select one</option>
        <option v-for="op in input.options" :key="op" :value="op.value">{{op.option}}</option>
      </select>
    </div>
</template>
   
<script>
   export default {
     name: 'DynamicSelect',
     props:{
        inputModel: null
     },
     data: function(){
       return{
        input: this.inputModel
       }
     }
   }
</script>
   