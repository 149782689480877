<template>
  <div v-if="CampagnePlanning" id="plans">
    <Intro 
      @bekijken="openBekijken()"
      :btnBekijken="true"
      :btnDoen="false"
      :info="CampagnePlanning.actieS_INFOTCAMPAGNEPLANNING"
      :title="'Campagneplanning'"
      :image="CampagnePlanning.actieS_AFBEELDINGTCAMPAGNEPLANNING"
      :imageUrl="'SKWINKELNetActieCampagneplanning'"
      :icon="'SKWINKELNetActieCampagneplanningIcoontje'"
    />

    <section v-if="CampagnePlanning.campagnePlanningen && CampagnePlanning.campagnePlanningen.length > 0 && openen" id="Planning">
      <div class="container">
        <div class="list-group list-group-flush">
          <div v-for="item in CampagnePlanning.campagnePlanningen" :key="item.campagneplanningeN_ID" class="list-group-item">
            <div class="row align-items-center">

                <div class="col-3">
                  {{item.campagneplanningeN_AANVANG}} - {{item.campagneplanningeN_EINDE}}
                </div>
                <div class="col-3">
                  {{item.mediA_MEDIUM}}
                </div>
                <div class="col-6">
                  {{item.campagneplanningeN_OMSCHRIJVING}}
                </div>

            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-7">
        <button type="button" @click="openBekijken()" class="btn btn-primary">Sluiten</button>
      </div>
    </section>
  </div>
</template>

<script>
import Intro from './IntroTables/Intro.vue'

export default {
  name: 'CampagnePlanning',
  props:{ actie: null },
  data:function(){
    return{
      openen : false
    }
  },
  components:{
    Intro
  },
  methods:{
    scrollToTop(){
        const id = 'plans';
        const yOffset = -150; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    },
    openBekijken(){
      this.openen = !this.openen;

      if(!this.openen)
        this.scrollToTop();
    }
  },
  computed:{
    CampagnePlanning(){
        return this.$store.state.actieStore.CampagnePlanning ? 
          this.$store.state.actieStore.CampagnePlanning.items : null;
    },
  },
  async created(){
    await this.$store.dispatch('actieStore/getCampagnePlanning', this.$route.params.id);
  }
}
</script>

<style scoped>
li{
  text-decoration: none;
}

ul{
  list-style-type: none;
  margin:0;
}

h1{
    font-weight: 900;
}

.titre{
  color:#5ca2be;
}
</style>