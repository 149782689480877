<template>
    <h2 class="fw-bold mb-1 mt-7">
      <div>{{pand.bedrijfsruimteS_STRAATTNR}} {{pand.bedrijfsruimteS_PLAATSNAAM}}</div>
    </h2>

    <p v-if="pand.bedrijfsruimteS_INFO" class="mt-3" style="overflow: hidden; max-height: 125px;">
      {{pand.bedrijfsruimteS_INFO}}
    </p>

    <h3 v-if="pand.bedrijfsruimteS_STATUS" class="mt-1" style="color:#1DAC00;text-transform:uppercase;">
      {{pand.bedrijfsruimteS_STATUS}}
    </h3><br/><br/>
    
    <router-link :to="{ name: 'pandOne', params: { id: pand.bedrijfsruimteS_ID }}"  class="btn btn-secondary" style="color:white !important;"  role="button">
        Naar bedrijfsruimte
    </router-link>  
</template>

<script>

export default {
  name: 'Pandm',
      props : {
        pand: null,
        sens: null
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.btn{
  width: 200px;
  padding: 10px !important;
  text-transform:uppercase;
  margin-left: 18px !important;
  border-radius: 5px !important;
  background-color: #22647A;
  border-color: #22647A;
  color: white !important;
}

.btn:hover{
  background-color: #154150;
  border-color: #154150;
}

h1{
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 40px;
}

p{
  color: #869ab8!important;
}

</style>