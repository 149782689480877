<template>
  <div class="mb-7">
    <Intro :actieId="actieId"/>
    <Doelstellingen :actieId="actieId" /> 
    <ActieVoorstellen ref="voorstel" :actieId="actieId" />
    <ActiePlanning :actieId="actieId" />
    <CampagnePlanning :actie="actie" />
    <Aanbod :actieId="actieId" />
    <Inschrijvingen :actieId="actieId" />
    <Campagnematerialen :actieId="actieId" />
    <Kpi :actieId="actieId" />
    <ActieEvaluaties ref="evaluatie" :actie="actie" :actieId="actieId" @open="openEvaluatie = !openEvaluatie"/>
  </div>
</template>

<script>
import Intro from './Intro.vue'
import Doelstellingen from './Doelstellingen.vue'
import ActieVoorstellen from './ActieVoorstellen.vue'
import ActiePlanning from './ActiePlanning.vue'
import CampagnePlanning from './CampagnePlanning.vue'
import Aanbod from './Aanbod.vue'
import Inschrijvingen from './Inschrijvingen.vue'
import Campagnematerialen from './Campagnematerialen.vue'
import Kpi from './KPI.vue'
import ActieEvaluaties from './ActieEvaluaties.vue'

export default {
  name: 'BannerActie',
  props:{
      actie: null,
      actieId: null
  },
  components:{
    Intro,
    Doelstellingen,
    ActieVoorstellen,
    ActiePlanning,
    CampagnePlanning,
    Aanbod,
    Inschrijvingen,
    Campagnematerialen,
    Kpi,
    ActieEvaluaties
  },
  data:function(){
    return{
      openEvaluatie: false,
      openVoorstel: true,
    }
  },
  methods:{
  }
}
</script>

<style>

.titreTumi{
  color:#5ca2be;
}

.nombreTumi{
  background-color:#5ca2be;
  color: white;
  padding:3px 5px 3px 5px;
  display: inline-block;
}

.fixedTumi{
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  right:0;
  z-index:1000000;
  background-color:#c3c7de;
  overflow-y:scroll;
  overflow-x:hidden;
}
</style>