

export const Verplicht = "Deze veld is verplicht.";
export const Email = "Dit moet een geldige email zijn.";
export const DontMatch = "De wachtwoorden verschillen."

export const Config = {
    Url,
    GetLoginRoute,
    SetLoginRoute
}

var LoginRoute = ["test"];

export const RouteAccount = Url() + "/Account/";

function Url(){
    return "";
    //return "https://localhost:7204";
}

function GetLoginRoute(){
    return LoginRoute;
}

function SetLoginRoute(v){
    LoginRoute = v;
}