<template>
  <div v-if="Kpi" id="kpis">
    <Intro
      :btnBekijken="true"
      @bekijken="openBekijken()"
      :title="'Kritieke prestatie indicatoren'"
      :info="Kpi.actieS_INFOTKPI"
      :image="Kpi.actieS_AFBEELDINGTKPI"
      :imageUrl="'SKWINKELNetActieKPI'"
      :icon="'SKWINKELNetActieKPIIcoontje'"
    />

    <section v-if="Kpi.kpis && Kpi.kpis.length > 0 && openen" id="Kpi">
      <div class="container">
        <div class="list-group list-group-flush">
          <div v-for="item in Kpi.kpis" :key="item.kpI_ID" class="list-group-item">
            <div class="row align-items-center">
              <div class="col-10">
                {{item.kpI_OMSCHRIJVING}}
              </div>
              <div class="col-2">
                <span class="float-end">{{item.kpI_AANTAL}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-7">
        <button type="button" @click="openBekijken()" class="btn btn-primary">Sluiten</button>
      </div>
    </section>
  </div>
</template>

<script>
import Intro from './IntroTables/Intro.vue'

export default {
  name: 'KPIS',
  props:{ actie: null },
  data: function(){
    return{
      openen: false
    }
  },
  methods:{
    scrollToTop(){
        const id = 'kpis';
        const yOffset = -150; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    },
    openBekijken(){
      this.openen = !this.openen;

      if(!this.openen)
        this.scrollToTop();
    },
  },
  components:{
    Intro
  },
  computed:{
    Kpi(){
        return this.$store.state.actieStore.Kpi ? 
          this.$store.state.actieStore.Kpi.items : null;
    },
  },
  async created(){
    await this.$store.dispatch('actieStore/getKpi', this.$route.params.id);
  }
}
</script>

<style scoped>

li{
  text-decoration: none;
}

ul{
  list-style-type: none;
  margin:0;
}

h1{
    font-weight: 900;
}

.titre{
  color:#5ca2be;
}

img{
  margin-bottom:4px;
  height:30px;
  margin-left:10px;
}

a{
  color:#5ca2be;
}

</style>