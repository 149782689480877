<template>
<div class="jauneBackground">
  <div class="container">
    <div class="text-center modTumi">
      <div class="text-start">
        <h2 class="mb-5">{{this.inschrijving ? "Inschrijvingsformulier" : "Niet-inschrijvingsformulier"}}</h2>
      </div>
      <input 
        type="text" 
        class="form-control mb-5"
        v-model="organisatieData.organisatieS_NAAM"
        placeholder="Naam organisatie">
      
      <input 
        type="text" 
        class="form-control mb-5"
        v-model="organisatieData.organisatieS_ETMAIL"
        placeholder="E-mail">

      <input 
        type="text" 
        class="form-control mb-5"
        v-model="organisatieData.organisatieS_MOBIELTNUMME"
        placeholder="Mobiel nummer">


      <textarea 
        v-model="voorstel"
        class="form-control mb-5"
        placeholder="Jouw suggesties/vragen/opmerkingen.">
      </textarea>

      <button type="button" @click="$emit('sluiten')" class="btn btn-danger float-start">Sluiten</button>
      <button type="button" @click="indienen" class="btn btn-danger float-end">{{this.inschrijving ? "Inschrijving insturen" : "Niet-Inschrijving insturen"}}</button>

      <div class="clearfix"></div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'RegistratiePopup',
  props:{
    actieId: null,
    actie: null,
    inschrijving: null,
    organisatie: null
  },
  data: function(){
    return{
      organisatieData: this.organisatie,
      voorstel:"",
    }
  },
  methods:{
    async indienen(){

      var res = {
        "AirIdOrg" : "TestOrg",
        "AirIdActie": this.actieId,
        "name": this.organisatieData.organisatieS_NAAM,
        "email": this.organisatieData.organisatieS_ETMAIL,
        "mobiel":this.organisatieData.organisatieS_MOBIELTNUMMER,
        "Opmerking": this.voorstel,
      }

      if(this.inschrijving)
      {
        await this.$store.dispatch('actieStore/sendRegistratieYes', res);
        this.$emit('continue', res);
      }
      else
      {
        await this.$store.dispatch('actieStore/sendRegistratieNo', res);
        this.$emit('stop', res);
      }

      this.$emit('continue', res);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
img{
  width:25px;
  height:25px;
}

img:hover{
  cursor: pointer;
}

.modTumi{
  border-radius:10px;
  padding:30px;
}

.back{
  background: #c3c7de;
}
</style>
