import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/styles/custom.scss"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import VueGoogleMaps from '@fawmi/vue-google-maps'

// importing AOS
import AOS from 'aos'
import 'aos/dist/aos.css'

createApp(App)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyCO0mxzdAcaVR1xQw2WEWfnBKATx_8bV08',
            libraries: 'places'
        },
    })
    .use(store)
    .use(router)
    .use(AOS.init())
    .mount('#app')

import 'bootstrap/dist/js/bootstrap.bundle'
