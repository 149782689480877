<template>
  <div class="colorTemplateBack">
    <div class="container">
      <template v-for="(item, index) in panden" :key="index">
        <div class="row">
          <Pand v-for="(subitem, index) in item" :key="index" :pand="subitem"/>
        </div>
      </template>
    </div>
  </div>
</template>


<script>

import Pand from '../Home/Panden/Pand.vue'

export default {
  name: 'StartPanden',
  props:{
    panden: null
  },
  components : {
      Pand
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.colorTemplateBack{
  background-color: rgb(248, 248, 248);
}
</style>