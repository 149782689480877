<template>
<h1 class="mb-3">Bedrijfsruimtes</h1>
<div class="list-group list-group-flush">
  <div class="list-group-item TumiTitle">
    <div class="align-items-center text-center row">
      <div class="col-2">
        <b>Benaming</b>
      </div>
      <div class="col-8">
        <b>Omschrijving</b>
      </div>          
      <div class="col-2">
        <b>Type</b>
      </div>               
    </div>
  </div>
  <div v-for="item in adressen" :key="item.vestigingsadresseN_bedrijfsruimteS_IDID" @click="clickAdresses(item.bedrijfsruimteS_ID)" class="list-group-item TumiClickable">
    <div class="align-items-center text-center row">
      <div class="col-2">
        {{item.bedrijfsruimteS_BENAMING}}
      </div>
      <div class="col-8">
        {{item.bedrijfsruimteS_OMSCHRIJVING}}
      </div>          
      <div class="col-2">
        {{item.bedrijfsruimteS_TYPE}}
      </div>            
    </div>
  </div>
</div>

<button @click="openCloseNouveau" class="btn btn-secondary float-end mt-3 mb-3">+</button>
<NewBedrijf v-if="openNouveau" @ajout="AjouterAdresse"/>
<DynamicPage v-if="activeObject" :objet="activeObject" :validate="validationError" @action="Act"/>
</template>
   
<script>
import NewBedrijf from './NewBedrijf.vue'
import DynamicPage from './DynamicPage.vue'

export default {
  name: 'BedrijfsRuimtes',
  props:{
    id: null
  },
  data:function(){
    return{
      openNouveau:false,
      path:{
          name:"Vestigingsadressen", 
          path: "/portaal/GetRuimtes?id=", 
          pathSet: "/portaal/SetVestigingsAdressen",
          validation: function(obj){
            if(obj != null)
              return null;
          }
      },
      activeObject: null,
      validationError: null
    }
  },
  components:{
    NewBedrijf,
    DynamicPage
  },
  computed:{
    adressen(){
      return this.$store.state.dynamicStore[this.path.path + this.id];
    }
  },
  methods:{
    async Act(retour){
      //ERREUR BLANCO
      this.validationError = null;

      //CHECK VALIDATION OF VIEW
      const res = this.path.validation(retour);
      
      //IF NOT VALIDATED SHOW ERROR
      if(res != null)
      {
        this.validationError = res;
        return;
      }

      //ELSE UPDATE DATABASE
      const pathSet = this.path.pathSet;
      await this.$store.dispatch('dynamicStore/setDynamic', {pathSet, retour});
    },
    clickAdresses(idAdresses){
      //IF WE'RE IN THE SAME ACTIVE OBJECT THAN THE ONE SELECTED AND THE NEW TAB ISN'T OPEN WE DEACTIVE THE OBJECT
      if(this.activeObject != null && this.activeObject.bedrijfsruimteS_ID == idAdresses && !this.openNouveau)
      {
        this.activeObject = null;
        return;
      }

      //IF CREATE OPEN, WE CLOSE IT
      if(this.openNouveau)
        this.openCloseNouveau();

      //GET THE ADRESS SELECTED
      const adresseCible = this.adressen.find(i=>i.bedrijfsruimteS_ID == idAdresses);

      //PASS IT TO THE DYNAMIC VIEW
      this.activeObject = adresseCible;
    },   
    openCloseNouveau(){
      this.activeObject = null;

      if(this.openNouveau)
      {
        this.$store.dispatch('dynamicStore/deleteSolidState','bedrijf');
        this.openNouveau = false; 
      }
      else
        this.openNouveau = true;

    },
    async AjouterAdresse(id){
      const retour = {"orgId" : this.id, "adrId" : id} 
      const pathSet = "/portaal/SetAdres"
      await this.$store.dispatch('dynamicStore/setDynamic', {pathSet, retour});
      this.openCloseNouveau();
      this.refresh()    
    },
    async refresh(){
      const path = this.path.path + this.id;
      await this.$store.dispatch('dynamicStore/getDynamic', path);
    }
  },
  async created(){
    await this.refresh();
  }
}
</script>   

<style>
  .TumiClickable{
    cursor: pointer;
  }

  .TumiClickable:hover{
    background-color: rgb(213, 211, 211);
  }

  .TumiTitle{
    background-color: rgb(225, 224, 224);
  }
</style>