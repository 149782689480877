import { formsService } from '../../services/Forms/forms.service'

export const formsStore = {
    namespaced: true,
    state: () => ({
        formKandidaat:null,
        formActie:null,
        formContact:null,
    }),
    actions: {
        async sendActies({ commit }, data){
            await formsService.sendActies(data)
            .then(
                error => commit('sendActies', error)
            )
        },
        async sendContact({ commit },data){
            await formsService.sendContact(data)
            .then(
                error => commit('sendContact', error)
            )
        },
        async sendKandidaat({ commit },data){
            await formsService.sendKandidaat(data)
            .then(
                error => commit('sendKandidaat', error)
            )
        }
    },
    mutations: {
        sendActies(state, error){
            state.formActie = error;
        },
        sendContact(state, error){
            state.formContact = error;
        },
        sendKandidaat(state, error){
            state.formKandidaat = error;
        }
    }
}