import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'

export const userService = {
    logout,

    Authenticate,
    GetAccountFromGuid,
    CreateAccount,
    RetrievePass,
    CheckIfNewPassGuidExists,
    SaveNewPass
};

async function Authenticate(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return await fetch(Config.Url() + '/user/authenticate', requestOptions)
        .then(handleResponse)
        .then(user => { return user; });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

async function GetAccountFromGuid(guid){
    const requestOptions = {
        method: 'GET',
    };

    return await fetch(Config.Url() + `/User/GetAccountFromGuid/` + guid, requestOptions)
        .then(handleResponse);
}

async function CreateAccount(account){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(account)
    };

    return await fetch(Config.Url() + `/User/CreateAccount`, requestOptions)
        .then(handleResponse);
}

async function RetrievePass(email){
    const requestOptions = {
        method: 'GET',
    };

    return await fetch(Config.Url() + `/User/RetrievePass/` + email, requestOptions)
        .then(handleResponse);
}

async function CheckIfNewPassGuidExists(guid){
    const requestOptions = {
        method: 'GET',
    };

    return await fetch(Config.Url() + `/User/CheckIfNewPassGuidExists/` + guid, requestOptions)
        .then(handleResponse);
}

async function SaveNewPass(pass){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(pass)
    };

    return await fetch(Config.Url() + `/User/SaveNewPass`, requestOptions)
        .then(handleResponse);
}