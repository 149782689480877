<template>
  <section class="position-relative pt-3 colorTemplateBack" id="Info">
    <div class="container mb-0 mt-7">
      <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-pause="false" data-bs-ride="carousel" data-bs-interval="2000">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="@/assets/start1.png" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="@/assets/start2.png" class="d-block w-100" alt="...">
          </div>
        </div>
      </div>
    </div>
    
  </section>

</template>

<script>

export default {
  name: 'BannerPanden'
}

</script>

<style scoped>
.colorTemplateBack{
  background-color: rgb(248, 248, 248);
}

.carousel{
  padding:0;
}

</style>