import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'

export const homeService = {
    getHome,
    getPanden
};

async function getHome() {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/HomePage/getHome`, requestOptions)
        .then(handleResponse)
        .then(
            missies => { 
                return missies
        });
}

async function getPanden() {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/HomePage/getPanden`, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}