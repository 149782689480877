<template>
<div class="jauneBackground">
  <div class="container">
    <div class="modTumi text-center">

      <div v-if="thanks" class="pt-14 pb-14">
          Bedankt voor jouw evaluatie!
      </div>

      <div v-if="!thanks">
        <div class="text-start">
          <h2 class="mb-5">Evaluatieformulier</h2>
        </div>
      
        <input 
          type="text" 
          class="form-control mb-5"
          v-model="organisatieData.organisatieS_NAAM"
          placeholder="Naam organisatie">
        
        <input 
          type="text" 
          class="form-control mb-5"
          v-model="organisatieData.organisatieS_ETMAIL"
          placeholder="E-mail">

        <input 
          type="text" 
          class="form-control mb-5"
          v-model="organisatieData.organisatieS_MOBIELTNUMMER"
          placeholder="Mobiel nummer">

        <textarea 
          v-model="voorstel"
          class="form-control mb-5"
            :placeholder="voorstelPlaceholder"
            :style="{
              'border': voorstelPlaceholder == 'Jouw evaluatie' ? '1px solid white' : '2px solid red',      
            }">

        </textarea>

        <h4>Hoeveel sterren wil jij deze actie geven?</h4>

        <div class="mb-5 container" style="max-width:300px">
          <div class="row">
            <div @click="SetRating(index)" class="col p-0 pointer" v-for="index in rating" :key="index">
              <img src="@/assets/StarOn.png"><br/>
              <div>
                {{index}}
              </div>
            </div>
            <div @click="SetRatingNeg(index)" class="col p-0 pointer"  v-for="index in 5 - rating" :key="index">
              <img src="@/assets/StarOff.png">
              <div>
                {{index + rating}}
              </div>
            </div>
          </div>
        </div>

        <button type="button" @click="$emit('sluiten')" class="btn btn-danger float-start">Sluiten</button>
        <button type="button" @click="indienen" class="btn btn-danger float-end">Evaluatie insturen</button>

        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {delay} from './../../../helpers/handleResponse.js'

export default {
  name: 'EvaluatiePopup',
  props:{
    actieId: null,
    actie: null,
    organisatie:null,
  },
  data: function(){
    return{
      organisatieData: this.organisatie,
      rating: 0,
      voorstel:"",
      thanks: false,
      voorstelPlaceholder : "Jouw evaluatie",
    }
  },
  methods:{
    SetRating(index){
      if(index == 1 && this.rating == 1)
        this.rating = 0;
      else
        this.rating = index;
    },
    SetRatingNeg(index){
      this.rating = this.rating + index;
    },
    async indienen(){

      if(this.voorstel == "")
      {
        this.voorstelPlaceholder = "Jouw evaluatie is een verplicht veld"
        return;
      }

      this.thanks = true;

      await delay(2);

      var res = {
        "name": this.organisatieData.organisatieS_NAAM,
        "email": this.organisatieData.organisatieS_ETMAIL,
        "mobiel":this.organisatieData.organisatieS_MOBIELTNUMMER,
        "rating": this.rating,
        "voorstel": this.voorstel,
        "actieId": this.actieId,        
        "orgAirId":this.organisatieData.organisatieS_AIRID
      }

      await this.$store.dispatch('actieStore/sendEvaluatie', res);

      this.$emit('clicked');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
img{
  width:25px;
  height:25px;
}

.pointer:hover{
  cursor: pointer;
}

.modTumi{
  border-radius:10px;
  padding:30px;
}

.back{
  background: #c3c7de;
}
</style>
