import { homeService } from '../../services/Home/home.service'

export const homeStore = {
    namespaced: true,
    state: () => ({
        home: {},
        error:{},
        panden:{},
        errorPanden:{}
    }),
    actions: {
        async getHome({ commit }){
            await homeService.getHome()
            .then(
                data => commit('getHomeSuccess', data),
                error => commit('getHomeFailure', error)
            );

            await homeService.getPanden()
            .then(
                data => commit('getPandenSuccess', data),
                error => commit('getPandenFailure', error)
            );    
        },

        async getPanden({commit}){
            await homeService.getPanden()
            .then(
                data => commit('getPandenSuccess', data),
                error => commit('getPandenFailure', error)
            );    
        }
    },
    mutations: {
        getHomeSuccess(state, data){
            state.home = data ;
        },
        getHomeFailure(state, error) {
            state.error = error;
        },
        getPandenSuccess(state, data){
            state.panden = data ;
        },
        getPandenFailure(state, error) {
            state.errorPanden = error;
        }
    }
}