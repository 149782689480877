<template>
  <div v-if="ruimte" :class="'col-12 col-md-4 p-1'">
    <div :class="{'card p-5 tumiCard': true , 'tumiSelected':sel }">
        <h3 class=" mb-1 text-center">
          <span v-if="ruimte && ruimte.benaming">{{ruimte.benaming}}</span>
        </h3>
        <h5 class=" mb-1 text-center">
            ({{ruimte.omschrijving}})
        </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FiltreCard',
  props:{
    ruimte: null,
    sel: null
  }
}
</script>

<style>
.colorTemplateBack{
  background-color: rgb(248, 248, 248);
}

.card{
  cursor: pointer;
}

.tumiCard{
  background-color:#86b4c6 !important;
  color:white;
}

.tumiCard:hover{
  background-color:#55727e !important;
  color:white;
}

.tumiSelected{
  background-color: #55727e !important;
  color:white;
}
</style>