<template>

    <div class="row align-item-center">
        <div class="col-12 col-md-7 col-lg-6 padding-text">
            <h1 class="display-3 text-center text-md-start mb-10">
                {{titre}}
            </h1>

            <p class="lead text-center text-md-start text-muted mb-6 mb-lg-8 fontSpecial">
                {{texte}}            
            </p>

            <div class="d-grid gap-2">
                <router-link v-if="id" :to="{ name: 'project', params: { id: id }}" class="btn btn-secondary">Naar project</router-link>
            </div>

        </div>
        
        <div class="col-12 col-md-5 col-lg-6 d-flex flex-wrap align-items-center">
            <img v-show="image" class="img-fluid mw-md-100 mw-lg-80 mb-6 mb-md-0" :src=image />
            <img v-show="!image" class="img-fluid mw-md-100 mw-lg-80 mb-6 mb-md-0" src="@/assets/Impact.png" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Doel',
    props : {
        id: String,
        titre: String,
        texte: String,
        image: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.padding-text{
    padding:70px;
}

</style>
