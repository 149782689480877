import { missieService } from '../../services/Missie/missies.service'

export const missieStore = {
    namespaced: true,
    state: () => ({
        missies: {},
        projecten:{}
    }),
    actions: {
        async getMissies({ commit }){
            await missieService.getAll()
            .then(
                missies => commit('getAllSuccess', missies),
                error => commit('getAllFailure', error)
            )
        },
        async getProjectenThemas({ commit }){
            await missieService.getProjectenThemas()
            .then(
                data => commit('getProjectenSuccess', data),
                error => commit('getProjectenFailure', error)
            )
        }
    },
    mutations: {
        getAllSuccess(state, missies){
            state.missies = { items: missies };
        },
        getAllFailure(state, error) {
            state.missies = { error };
        },
        getProjectenSuccess(state, data){
            state.projecten = { items: data };
        },
        getProjectenFailure(state, error) {
            state.projecten = { error };
        }
    }
  }