<template>
  <h1>Nieuwe bedrijfsruimte toevoegen</h1>
  <input 
    type="text" 
    v-model="searchterm"
    class="form-control"
    placeholder="Zoeken"
    autocomplete="off" 
  />

  <div v-for="item in adressen" :key="item.vestigingsadresseN_ID" @click="$emit('ajout',item.adresseN_ID)" class="list-group-item TumiClickable">
    <div class="align-items-center text-center row">
      <div class="col-2 text-start">
        {{item.bedrijfsruimteS_BENAMING}}
      </div>
      <div class="col-8 text-start">
        {{item.bedrijfsruimteS_OMSCHRIJVING}}
      </div>          
      <div class="col-2 text-start">
        {{item.bedrijfsruimteS_TYPE}}
      </div>  
    </div>
  </div>

</template>
   
<script>
   export default {
     name: 'NewBedrijf',
     emits:['ajout'],
     data:function(){
      return{
        searchterm: "",
        variable: "bedrijf"
      }
     },
     methods:{
      GetAdressen(searchterm){
        const path = '/portaal/SearchRuimtes?searchField=' + searchterm;
        const stateName = this.variable;

        this.$store.dispatch('dynamicStore/getDynamicSolidState', {path, stateName});
      }
     },
     computed:{
      adressen(){
        return this.$store.state.dynamicStore[this.variable];
      }
     },
     watch:{
      searchterm(e){
        if(e)
          this.GetAdressen(e);
        else        
          this.$store.dispatch('dynamicStore/deleteSolidState','bedrijf');
      }
     }
   }
</script>   

<style>

</style>