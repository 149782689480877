<template>
  <div class="container d-flex flex-column">

    <div v-if="checking">
      {{serverError}}
    </div>
    
    <div v-if="!checking">
      <div class="row align-items-center justify-content-center gx-0 min-vh-90">
        <div v-if="succes" class="col-12 col-md-5 col-lg-4 mt-10">
            <img src="@/assets/Account/Geactiveerd5FA7C4.png" class="img-fluid"/>
            <h1 v-if="succes" class="text-center mt-5">
              Het wachtwoord van jouw account werd opnieuw ingesteld. <br/>Je kan je nu <router-link :to="{ name: 'account', params: { guid : succes }}">HIER</router-link> aanmelden.
            </h1>
        </div>


        <div v-if="!succes" class="col-12 col-md-5 col-lg-4 py-8 py-md-11">
          <img src="@/assets/Account/Wachtwoord5FA7C4.png" class="img-fluid mb-5"><br/>

          <!-- Heading -->
          <h1 class="mb-7 fw-bold text-center">
            Nieuw wachtwoord account SKWINKELNet instellen.          
          </h1>

          <!-- Form -->
          <!-- Password1 -->
          <div class="form-group mb-5">
            <input 
              v-model="password1" 
              id="password1" 
              type="password" 
              class="form-control" 
              placeholder="Vul een nieuw wachtwoord in.">
          </div>
          
          <!-- Password2 -->
          <div class="form-group mb-5">
            <input 
              v-model="password2" 
              id="password2" 
              type="password" 
              class="form-control" 
              placeholder="Vul het nieuw wachtwoord nogmaals in.">
          </div>
            
          <p class="text-danger" v-if="error">{{error}}</p>

          <!-- Submit -->
          <button class="btn w-100 btn-secondary" @click="ChangePass">
            Instellen
          </button>
        </div>
      </div>    
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewPass',
  data:function(){
    return{
      Account: this.AccountProp,
      password1: "",
      password2: "",
      error: null,
      serverError: null,
      succes: null,
      checking: "checking..."
    }
  },
  methods:{
    async ChangePass(){
      if(this.password1 == "" || this.password2 == "")
      { 
        this.error = "De velden zijn verplicht.";
        return;
      }  

      if(this.password1 != this.password2)
      {
        this.error = "De wachtwoorden komen niet overeen.";
        return;
      }

      var res = {
        "password" : this.password1,
        "Guid": this.$route.params.guid,
      }

      await this.$store.dispatch('authenticationStore/SaveNewPass', res);

      this.succes = this.$store.state.authenticationStore.newPass;
    }
  },
  async created(){
    await this.$store.dispatch('authenticationStore/CheckIfNewPassGuidExists', this.$route.params.guid);
    
    if(this.$store.state.authenticationStore.error)
    {
      this.serverError = this.$store.state.authenticationStore.error;      
      return;
    }

    this.checking = null;
  }
}
</script>

<style>

</style>