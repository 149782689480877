<template>
<section class="position-relative pt-12 pt-md-8 mt-n11">
      <!-- Content -->
      <div class="container">
      <div class="row align-items-center text-center text-md-start">
          <div class="col-12 col-md-6">
            <!-- Heading -->
            <h1 class="display-1 fw-bold">
              <div data-aos="fade-left" class="aos-init aos-animate">{{donnees[1]}}</div>
            </h1>

            <!-- Text -->
            <p class="fs-lg text-muted mb-0 aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
              {{donnees[2]}}
            </p>

            <div v-if="donnees[3]" class="d-grid gap-2 mt-7">
                <router-link :to="{ name: 'actie', params: { id: donnees[3] }}" class="btn btn-secondary">Naar actie</router-link>
            </div>

          </div>
          <div class="col-12 col-md-6">
            <!-- Image -->
            <img src="@/assets/illustration-4.png" alt="..." class="img-fluid mw-130 float-end mb-6 mb-md-0 aos-init aos-animate" data-aos="fade-right">

          </div>
        </div>         
    </div>
  </section>


</template>

<script>

export default {
  name: 'Missie',
      props : {
        donnees: null
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@media (min-width: 992px){
  .container, .container-lg, .container-md, .container-sm {
      max-width: 960px;
  }
}
@media (min-width: 768px){
  .container, .container-md, .container-sm {
      max-width: 720px;
  }
}

@media screen and (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1040px;
}
} 

.col-12{
  padding: 20px;
}

h1{
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 40px;
}

p{
  color: #869ab8!important;
}

</style>