<template>
    <div class="col-12 col-md-4 text-center" v-if="items">

        <img v-if="items.image" :src="JSON.parse(items.image)[0]" height="75" style="margin-bottom:40px;">
        <h3 v-if="items.title">
            <span>{{items.title}}</span>
        </h3>

        <p class="mb-6 mb-md-0">
            <span>{{items.text}}</span>
        </p>
        <button v-if="items.button" class="btn btn-danger">
            <h3 v-if="items.titleButton" class="mb-3">
                {{items.titleButton}}
            </h3>
            {{items.button}}
        </button>
    </div>
</template>

<script>
import {shadeColor} from '../../helpers/handleResponse'

export default {
    name: 'UspItem',
    data:function(){
        return{
            colorDarker:null,
            colorLight:null,
            color:"#5ea7c4",
        }
    },
    props : {
        items:{}
    },
    created(){
        this.colorDarker = shadeColor(this.color, -40);
        this.colorLight = shadeColor(this.color, -20);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.btn{
    margin-top:20px;
}

.btn-danger{
  background-color: v-bind('color') !important;
  border-color: v-bind('color') !important;
  font-weight: 100;
}

.btn-danger:hover{
  background-color: v-bind('colorDarker') !important;
  border-color: v-bind('colorDarker') !important;
}

</style>
