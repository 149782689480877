<template>
  <div v-if="Account" class="row align-items-center justify-content-center gx-0 min-vh-90">
    <div v-if="succes" class="col-12 col-md-5 col-lg-4 mt-10">
        <img src="@/assets/Account/Geactiveerd5FA7C4.png" class="img-fluid"/>
        <h1 v-if="succes" class="text-center mt-5">
          Jouw account werd geactiveerd. <br/>Je kan je nu <a @click="Refresh()" style="cursor:pointer;">HIER</a> aanmelden.
        </h1>
    </div>


    <div v-if="!succes" class="col-12 col-md-5 col-lg-4">
      <img src="@/assets/Account/Activeren5FA7C4.png" class="img-fluid mb-5"><br/>
      
      <!-- Heading -->
      <h1 class="mb-7 fw-bold text-center">
        Activeer jouw account op het SKWINKELNet.
      </h1>

      <!-- Form -->
      <div class="form-group mb-5">
        <label class="form-label" for="email">
          E-mail
        </label>
        <input 
          v-model="Account.email" 
          id="email" 
          type="email" 
          class="form-control"
          style="pointer-events: none;">
      </div>

      <!-- Password1 -->
      <div class="form-group mb-5">
        <label class="form-label" for="password1">
          Wachtwoord
        </label>
        <input 
          v-model="password1" 
          id="password1" 
          type="password" 
          class="form-control" 
          placeholder="Vul een wachtwoord in.">
      </div>
      
      <!-- Password2 -->
      <div class="form-group mb-5">
        <label class="form-label" for="password2">
          Herhaal wachtwoord
        </label>
        <input 
          v-model="password2" 
          id="password2" 
          type="password" 
          class="form-control" 
          placeholder="Vul het wachtwoord nogmaals in.">
      </div>
        
      <p class="text-danger" v-if="error">{{error}}</p>

      <!-- Submit -->
      <button class="btn w-100 btn-secondary" @click="Create">
        Activeren
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreateAccount',
  props:['AccountProp'],
  data:function(){
    return{
      Account: this.AccountProp,
      password1: "",
      password2: "",
      error:null,
      succes: false
    }
  },
  methods:{
    Refresh(){
      this.$router.go()
    },
    async Create(){
      if(this.password1 == "" || this.password2 == "")
      { 
        this.error = "De velden zijn verplicht.";
        return;
      }  

      if(this.password1 != this.password2)
      {
        this.error = "De wachtwoorden komen niet overeen.";
        return;
      }

      var res = {
        "password" : this.password1,
        "email":this.Account.email,
        "orgAirId": JSON.parse(this.Account.orgId)[0]
      }

      await this.$store.dispatch('authenticationStore/CreateAccount', res);

      this.succes = true;
    }
  }
}
</script>

<style>
a{
  color:#5ca2be !important;
}

a:hover{
  color:#41778c !important;
}

</style>