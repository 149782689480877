<template>
    <div :class="input.divClass">
        <input 
            type="text" 
            :class="input.class"
            v-model="input.value"
            :placeholder="input.placeholder"
            :disabled="input.disabled">
    </div>
</template>
   
<script>
   export default {
     name: 'DynamicComponent',
     props:{
        inputModel: null
     },
     data: function(){
       return{
        input: this.inputModel
       }
     }
   }
</script>
   