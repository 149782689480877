<template>
<div v-if="producten" id="aans">
    <Intro 
        :imageGauche="true"
        :btnBekijken="true"
        @bekijken="openBekijken"
        :title="'Deelnemen'"
        :image="producten.actieS_AFBEELDINGTOMSCHRIJVING"
        :info="producten.actieS_INFOTAANBOD"
        :imageUrl="'SKWINKELNetActieDeelnemen'"
        :icon="'SKWINKELNetActieDeelnemenIcoontje'"
    />

    <section v-if="producten.producten && producten.producten.length > 0 && openen" id="Kpi">
      <div class="container mt-5">
        <div class="list-group list-group-flush">
          <div class="list-group-item colomKoppen">
            <div class="row align-items-center ">
                  <div class="col-2">
                  </div>
                  <div class="col-2">
                    <h4>Benaming</h4>
                  </div>
                  <div class="col-6">
                    <h4>Omschrijving</h4>
                  </div>
                  <div class="col-1">
                    <h4>Prijs</h4>
                  </div>
                  <div class="col-1">
                    <h4>BTW</h4>
                  </div>
            </div>
          </div>
          <div v-for="item in producten.producten" :key="item.actieproducteN_ID" class="list-group-item">
            <div class="row align-items-center">
                <div class="col-2" style="font-family:HKGroteskPro;">
                  {{item.actieproducteN_ACTIEPROFIEL}}
                </div>
                <div class="col-2">
                  {{item.actieproducteN_BENAMING}}
                </div>
                <div class="col-6">
                  {{item.actieproducteN_OMSCHRIJVING}}
                </div>
                <div class="col-1">
                  {{item.actieproducteN_EENHEIDSPRIJS}} €
                </div>
                <div class="col-1">
                  {{item.actieproducteN_BTWTT}} %
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-7">
        <button type="button" @click="openBekijken" class="btn btn-primary">Sluiten</button>
      </div>
    </section>

</div>
</template>


<script>

import Intro from './IntroTables/Intro.vue'

export default {
  name: 'Aanbod',
  data:function(){
    return{
      openen:false
    }
  },
  components:{
      Intro
  },
  props:{
      actieId: null
  },
  methods:{
    scrollToTop(){
        const id = 'aans';
        const yOffset = -150; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    },
    openBekijken(){
      this.openen = !this.openen;

      if(!this.openen)
        this.scrollToTop();
    }
  },
  computed:{
    producten(){
      return this.$store.state.actieStore.producten ? this.$store.state.actieStore.producten.items : null;
    }
  },
  async created(){
    this.$store.dispatch('actieStore/getProducten', this.actieId);
  }
}
</script>

<style scoped>

</style>