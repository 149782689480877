<template>
  <div class="home" v-if="proj && proj.items">
    <Project :project="proj.items" />
  </div>
  
  <div v-if="acties && acties.items.length" class="mb-15">
    <div v-for="(item, index) in acties.items" :key="index" class="mt-10">
      <Missie v-if="index % 2 === 0"  :donnees="item"/>
      <MissieInverse v-if="index % 2 !== 0"  :donnees="item" />    
    </div>
  </div>
</template>

<script>
import Project from '../../components/Projecten/Project.vue'
import Missie from '../../components/Projecten/Missie.vue'
import MissieInverse from '../../components/Projecten/MissieInverse.vue'

export default {
  name: 'Home',
  components: {
    Project,
    Missie,
    MissieInverse
  }
  ,computed:{
    proj(){
        return this.$store.state.actieStore.project;
    },
    acties(){
        return this.$store.state.actieStore.acties
    }
  },
  async created(){
    await this.$store.dispatch('actieStore/getProject', this.$route.params.id);
    await this.$store.dispatch('actieStore/getActies', this.$route.params.id);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>