import { ledenService } from '../../services/Leden/leden.service'

export const ledenStore = {
    namespaced: true,
    state: () => ({
        leden: {}
    }),
    actions: {
        async getLeden({ commit }){
            await ledenService.getAll()
            .then(
                leden => commit('getAllSuccess', leden),
                error => commit('getAllFailure', error)
            )
        }
    },
    mutations: {
        getAllSuccess(state, leden){
            state.leden = { items: leden };
        },
        getAllFailure(state, error) {
            state.leden = { error };
        }
    }
  }