<template>
<h1 class="mb-3">Teamleden</h1>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
<div class="list-group list-group-flush">
  <div class="list-group-item TumiTitle">
    <div class="align-items-center text-start row">
      <div class="col-4">
        <b>Voornaam</b>
      </div>
      <div class="col-4">
        <b>Familienaam</b>
      </div>          
      <div class="col-4">
        <b>Functie</b>
      </div>         
    </div>
  </div>
  <div v-for="item in team" :key="item.teamledeN_ID" @click="clickTeam(item.teamledeN_ID)" class="list-group-item TumiClickable">
    <div class="align-items-center text-start row">
      <div class="col-4">
        {{item.teamledeN_VOORNAAM}}
      </div>
      <div class="col-4">
        {{item.teamledeN_FAMILIENAAM}}
      </div>          
      <div class="col-4">
        {{item.teamledeN_FUNCTIE}}
      </div>              
    </div>
  </div>
</div>

<button @click="openCloseNouveau" class="btn btn-secondary float-end mt-3 mb-3">+</button>
<div class="clearfix"></div>
<NewTeam v-if="openNouveau" :id="id" @refr="NewCreated"/>
<DynamicPage v-if="activeObject" :objet="activeObject" :name="name" :desc="desc" :validate="validationError" @action="Act"/>
</template>
   
<script>
import DynamicPage from './DynamicPage.vue'
import NewTeam from './NewTeam.vue'

export default {
  name: 'TableTeam',
  props:{
    id: null
  },
  components:{
    DynamicPage,
    NewTeam
  },
  data:function(){
    return{
      openNouveau:false,
      path:{
          name:"TeamLeden", 
          path: "/portaal/GetTeam?id=", 
          pathSet: "/portaal/SetTeam",
          validation: function(obj){
            if(obj != null)
              return null;
          }
      },
      activeObject: null,
      validationError: null,
      adrId: null,
      name:'Gegevens teamlid aanpassen.',
      desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    }
  },
  computed:{
    team(){
      return this.$store.state.dynamicStore[this.path.path + this.id];
    }
  },
  methods:{
    NewCreated(){
      this.refresh();
      this.openCloseNouveau();
    },
    async Act(retour){
      //ERREUR BLANCO
      this.validationError = null;

      //CHECK VALIDATION OF VIEW
      const res = this.path.validation(retour);
      
      //IF NOT VALIDATED SHOW ERROR
      if(res != null)
      {
        this.validationError = res;
        return;
      }

      //ELSE UPDATE DATABASE
      const pathSet = this.path.pathSet;
      await this.$store.dispatch('dynamicStore/setDynamic', {pathSet, retour});
    },
    clickTeam(idTeam){
      //IF WE'RE IN THE SAME ACTIVE OBJECT THAN THE ONE SELECTED AND THE NEW TAB ISN'T OPEN WE DEACTIVE THE OBJECT
      if(this.activeObject != null && this.activeObject.teamledeN_ID == idTeam && !this.openNouveau)
      {
        this.activeObject = null;
        return;
      }

      //IF CREATE OPEN, WE CLOSE IT
      if(this.openNouveau)
        this.openCloseNouveau();

      //GET THE ADRESS SELECTED
      const adresseCible = this.team.find(i=>i.teamledeN_ID == idTeam);

      //PASS IT TO THE DYNAMIC VIEW
      this.activeObject = adresseCible;
      this.adrId = this.activeObject.teamledeN_ID;
    },   
    openCloseNouveau(){
      this.activeObject = null;

      if(this.openNouveau)
        this.openNouveau = false; 
      else
        this.openNouveau = true;

    },
    async refresh(){
      const path = this.path.path + this.id;
      await this.$store.dispatch('dynamicStore/getDynamic', path);
    }
  },
  async created(){
    await this.refresh();
  }
}
</script>   

<style>

</style>