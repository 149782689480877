<template>
  <section v-if="actie">
    <div class="container text-center mt-10 mb-7">
        <div class="row text-md-start aos-init aos-animate mt-5 align-items-center" data-aos="fade-left">
            <div v-if="imageGauche" class="col-5">
                <img src="" class="img-fluid mw-100" alt="beeld Omschrijving">
            </div>
            <div :class="{'col-7':true, 'ps-10':imageGauche, 'pe-10':!imageGauche}">
                
                {{actie.actieS_AANVANGTACTIEPERIODE}} - {{actie.actieS_EINDETACTIEPERIODE}}<br/>

                <h2 class="mb-3 titreTumi">
                    {{actie.actieS_BENAMING}}
                </h2>
                {{actie.actieS_OMSCHRIJVING}}<br/>

                <router-link :to="{ name: 'actie', params: { id: actie.actieS_AIRID }}"  class="btn btn-secondary">Naar Actie</router-link>  
            </div>
            <div v-if="!imageGauche" class="col-5">
                <img src="eee" class="img-fluid mw-100" alt="beeld Omschrijving">
            </div>
        </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'ActieModule',
  props:{
    actie: null,
    imageGauche:null
  }
}
</script>

<style scoped>

</style>