<template>
  <div class="container">

    <h1 class="text-center display-1 mb-7">Projecten</h1>
    
    <p class="lead text-center  text-muted mb-6 mb-lg-8 fontSpecial">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>

    <div v-for="(item, index) in projecten" :key="item">
      <Doel v-if="index % 2 === 0" :id="item.projecteN_AIRID" :titre="item.projecteN_PROJECT" :texte="item.projecteN_TEKSTTHOMEPAGINA" :image="item.projecteN_AFBEELDINGTHOMEPAGINA"/>
      <DoelInverse v-if="index % 2 != 0"  :id="item.projecteN_AIRID" :titre="item.projecteN_PROJECT" :texte="item.projecteN_TEKSTTHOMEPAGINA" :image="item.projecteN_AFBEELDINGTHOMEPAGINA"/>
    </div>
  </div>
</template>

<script>
import Doel from '../../components/Projecten/Doel.vue'
import DoelInverse from '../../components/Projecten/DoelInverse.vue'

export default {
  name: 'Projecten',
  components: {
    Doel,
    DoelInverse
  },
  computed:{
    projecten(){
      return this.$store.state.missieStore.missies.items;
    }
  },
  async created(){
    this.$store.dispatch('missieStore/getMissies');
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>