import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'
import { authHeader } from '../../helpers/auth-header';

export const organisatiesService = {
    getOrganisatie,
    getOrganisaties,
    getFacturatie
};

async function getOrganisatie(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return await fetch(Config.Url() + `/Organisaties/GetOrganisatie?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getOrganisaties() {
    const requestOptions = {
        method: 'GET',
    };

    return await fetch(Config.Url() + `/Organisaties/GetOrganisaties`, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getFacturatie(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return await fetch(Config.Url() + `/Organisaties/GetFacturatie?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}