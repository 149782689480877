<template>
  <!-- <Navigation v-if="!$route.meta.hideNavbar"/> -->
  <!-- <div class="espace"></div> -->
  <router-view/>
</template>

<script>
// import Navigation from '../src/components/Nav/Navigation.vue'

export default {
  name: 'App',
  components: {
    // Navigation
  }
}
</script>


<style lang="scss">
.espace{
  height:55px;
}
</style>
