import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'

export const uspService = {
    getAll,
    getOne,
    getStrategies,
    getWaarden,
    GetDoelstellingen
};

async function getWaarden(){
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url()  + `/HomePage/GetWaarden`, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function GetDoelstellingen(){
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url()  + `/HomePage/GetDoelstellingen`, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getAll() {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url()  + `/HomePage/GetDoelstellingen`, requestOptions)
        .then(handleResponse)
        .then(
            usps => { 
                return usps
        });
}

async function getOne(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url()  + `/HomePage/GetDoelstelling?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            usps => { 
                return usps
        });
}

async function getStrategies(id){
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url()  + `/HomePage/?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            strategies => { 
                return strategies
        });
}
