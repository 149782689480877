import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'

export const formsService = {
    sendActies,
    sendContact,
    sendKandidaat
};

async function sendActies(data){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return await fetch(Config.Url() + `/Forms/Acties`, requestOptions)
        .then(handleResponse);
}

async function sendContact(data){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return await fetch(Config.Url() + `/Forms/Contact`, requestOptions)
        .then(handleResponse);
}

async function sendKandidaat(data){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return await fetch(Config.Url() + `/Forms/Kandidaat`, requestOptions)
        .then(handleResponse);
}
