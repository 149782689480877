<template>
  <div class= "pt-10 pb-10" :style="index % 2 != 0 ? 'background-color:#f3f3f3;' : 'background-color:white;'">
    <div class="container">
      <div class="row text-md-start">
            <!-- Image -->
            <img v-if="pand.bedrijfsruimteS_AFBEELDING" :src="getImgUrl(pand.bedrijfsruimteS_AFBEELDING)" class="img-fluid mb-6 mb-md-0"/>
            <img v-if="!pand.bedrijfsruimteS_AFBEELDING" src="@/assets/1.jpg" alt="..." class="img-fluid mb-6 mb-md-0"/>
      </div>      
      <div class="row text-md-start">
          <SousPand :pand="pand" :sens="'left'"/>
      </div>
    </div>
  </div>
</template>

<script>
import SousPand from './SousPand.vue'


export default {
  name: 'Pandm',
      props : {
        pand: null,
        index: null
  },
  components: {
    SousPand
  },
  methods:{
    getImgUrl(pet) {
      return require('../../../assets/Panden/'+ pet)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.col-12{
  padding: 20px;
}

h1{
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 40px;
}

p{
  color: #869ab8!important;
}

</style>