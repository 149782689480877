import { uspService } from '../../services/Doelstellingen/usps.service'

export const uspStore = {
    namespaced: true,
    state: () => ({
        usps: {},
        usp: {},
        doelstellingen: {},
        waarden: {}
    }),
    actions: {
        async getWaarden({commit}){
            await uspService.getWaarden()
            .then(
                data => commit('getWaardenSuccess', data),
                error => commit('getWaardenFailure', error)
            )
        },
        async getUsps({ commit }){
            await uspService.getAll()
            .then(
                usps => commit('getAllSuccess', usps),
                error => commit('getAllFailure', error)
            )
        },
        async getDoelstellingen({ commit }){
            await uspService.GetDoelstellingen()
            .then(
                data => commit('getDoelstellingenSuccess', data),
                error => commit('getDoelstellingenFailure', error)
            )
        },
        async getUsp({commit}, id){
            await uspService.getOne(id)
            .then(
                usp => commit('getOneSuccess', usp),
                error => commit('getOneFailure', error)
            )

            await uspService.getStrategies(id)
            .then(
                strategies => commit('getStrategiesSuccess', strategies),
                error => commit('getStrategiesFailure', error)
            )
        }
    },
    mutations: {
        getWaardenSuccess(state, data){
            state.waarden = { items: data };
        },
        getWaardenFailure(state, error) {
            state.waarden = { error };
        },
        getAllSuccess(state, usps){
            state.usps = { items: usps };
        },
        getAllFailure(state, error) {
            state.usps = { error };
        },
        getOneSuccess(state, usp){
            state.usp = { item: usp };
        },
        getOneFailure(state, error) {
            state.usp = { error };
        },
        getStrategiesSuccess(state, strategies){
            state.strategies = { items: strategies };
        },
        getStrategiesFailure(state, error) {
            state.strategies = { error };
        },
        getDoelstellingenSuccess(state, data){
            state.doelstellingen = { items: data };
        },
        getDoelstellingenFailure(state, error) {
            state.doelstellingen = { error };
        }
    }
  }