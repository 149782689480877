import { organisatiesService } from '../../services/Organisaties/organisaties.service'

export const organisatiesStore = {
    namespaced: true,
    state: () => ({
        organisatie: null,
        organisatieError: null,
        organisaties: null,
        organisatiesError: null,
        facturatie:null,
        facturatieError:null
    }),
    actions: {
        async getOrganisatie({commit}, id){
            await organisatiesService.getOrganisatie(id)
            .then(
                data => commit('getOrganisatieSuccess', data),
                error => commit('getOrganisatieFailure', error)
            )
        },
        async getOrganisaties({commit}, id){
            await organisatiesService.getOrganisaties(id)
            .then(
                data => commit('getOrganisatiesSuccess', data),
                error => commit('getOrganisatiesFailure', error)
            )
        },
        async getFacturatie({commit}, id){
            await organisatiesService.getFacturatie(id)
            .then(
                data => commit('getFacturatieSuccess', data),
                error => commit('getFacturatieFailure', error)
            )
        }
    },
    mutations: {
        getOrganisatieSuccess(state, data){
            state.organisatie = data;
        },
        getOrganisatieFailure(state, error) {
            state.organisatieError = error;
        },
        getOrganisatiesSuccess(state, data){
            state.organisaties = data;
        },
        getOrganisatiesFailure(state, error) {
            state.organisatiesError = error;
        },
        getFacturatieSuccess(state, data){
            state.facturatie = data;
        },
        getFacturatieFailure(state, error) {
            state.facturatieError = error;
        }
    }
  }