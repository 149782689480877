import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'
import { authHeader } from '../../helpers/auth-header';

export const dynamicService = {
    getDynamic,
    setDynamic
};

async function getDynamic(path) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return await fetch(Config.Url() + path, requestOptions)
        .then(handleResponse)
        .then(
            data => {
                return data;
        });
}

async function setDynamic(path, obj) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: authHeader()
    };

    return await fetch(Config.Url() + path, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}