<template>
  <section>
      <div v-for="z in zonesDivided" :key="z" class="row">
        <RuimteCard 
          v-for="zone in z" :key="zone" 
          :sel="selected == zone.zoneS_AIRID"  
          :ruimte="{'benaming': zone.zoneS_BENAMING, 'omschrijving' :  zone.zoneS_RUIMTE}"
          :filtre="true"
          @click="change(zone.ruimteS_AIRID)"/>
      </div>


      <!-- <div class="row">
        <RuimteCard v-for="i in 3" :sel="selected == ruimtes.listRuimtes[i - 1].ruimteS_AIRID" :key="i" :ruimte="ruimtes.listRuimtes[i - 1]" @click="change(ruimtes.listRuimtes[i - 1].ruimteS_AIRID)"/>
      </div>
      <div class="row">
        <RuimteCard v-for="i in 3" :sel="selected == ruimtes.listRuimtes[i + 2].ruimteS_AIRID" :key="i" :ruimte="ruimtes.listRuimtes[i + 2]" @click="change(ruimtes.listRuimtes[i + 2].ruimteS_AIRID)"/>
      </div> -->
  </section>
</template>

<script>
import {divisionEnChunk} from './../../helpers/handleResponse'
import RuimteCard from './FiltreCard.vue'

export default {
  name: 'Ruimtes',
  props:{
    zones: null
  },
  data:function(){
    return{
      zonesDivided: null,
      selected: null
    }
  },
  components:{
    RuimteCard
  },
  methods:{

  },
  created(){
      if(this.zones)
        this.zonesDivided = divisionEnChunk(this.zones,3);
  }
}
</script>

<style>

</style>