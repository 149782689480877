<template>
<div v-if="Deelnemers" class="container">
  <h3 v-if="total">Voor deze actie werden {{total}} inschrijvingen ingestuurd.</h3>
  <!-- <div class="jauneBackground mt-5" style="height:5px"></div> -->
  <div v-for="doelgroep in Deelnemers" :key="doelgroep">
  <section v-if="doelgroep.orgs && doelgroep.orgs.length > 0" id="Deelnemers">
    <div>
        <!-- <div class="nombreTumi jauneBackground mb-10">
            Deze actie telt {{doelgroep.orgs.length}} {{JSON.parse(doelgroep.profiel)[0]}}
        </div> -->

      <div class="list-group list-group-flush">        
          <div v-for="item in doelgroep.orgs" :key="item" class="list-group-item">
            <div class="row align-items-center mt-5">
               <div class="col">
                 <PartnerDeelnemer 
                    :naam="item.organisatieS_NAAM"
                    :adres="item.organisatieS_STRAAT + ' ' + item.organisatieS_GEMEENTE"
                    :email="item.organisatieS_ETMAIL"
                    :tel="item.organisatieS_TELEFOONNUMMER"
                    :mobiel="item.organisatieS_MOBIELTNUMMER"
                    :web="item.organisatieS_WEBSITE"
                    :Facebook="item.organisatieS_FACEBOOKPAGINA"
                    :Insta="item.organisatieS_INSTAGRAMPROFIEL"
                 /> 
              </div>
            </div>
          </div>
      </div>
      
      <!-- <div class="text-center mt-7">
      <button v-if="aantal != Deelnemers.deelnemers.length" type="button" @click="showMore()" class="btn btn-primary">meer deelnemers tonen</button>
      </div> -->
    </div>
  </section>
  </div>

</div>
</template>

<script>

import PartnerDeelnemer from './PartnerDeelnemer/PartnerDeelnemer.vue'
export default {
  name: 'Deelnemers',
  components:{
    PartnerDeelnemer
  },
  props:{
      actieId: null,
      Deelnemers: null,
      total:null
  },
  data: function(){
    return{
      aantal : 0,
      clicked : 0 
    }
  },
  methods:{
    showMore(){
      this.clicked++;
      this.update();
    },
    update(){
      if(!this.Deelnemers.deelnemers)
        this.aantal = 0;
      else
      {
        var enPlus = 5 * this.clicked;
        var afficher = 5 + enPlus;
        this.aantal = this.Deelnemers.deelnemers.length < afficher ? this.Deelnemers.deelnemers.length : afficher;
      }
    }
  },
  watch:{
    Deelnemers(){
      this.update()
    }
  }
}
</script>

<style scoped>

li{
  text-decoration: none;
}

ul{
  list-style-type: none;
  margin:0;
}

h1{
    font-weight: 900;
}

img{
  margin-bottom:4px;
  height:30px;
  margin-left:10px;
}

a{
  color:#5ca2be;
}

.list-enter-from{
  opacity:0;
  transform:scale(0);
}

.list-enter-to{
  opacity:1;
  transform:scale(1);
}

.list-enter-active{
  transition:all 0.4s ease;
}

</style>