<template>
  <div v-if="actiesApres && actiesAvant">
    <AllActiesPagina :AllActiesApres="actiesApres" :AllActiesAvant="actiesAvant" />
  </div>
</template>

<script>
import AllActiesPagina from '../../components/AllActies/AllActiesPagina.vue'

export default {
  name: 'AllActies',
  computed:{
    actiesAvant(){

        return this.$store.state.actieStore.AllActies && this.$store.state.actieStore.AllActies.itemsAvant ? 
          this.$store.state.actieStore.AllActies.itemsAvant :
          null;
    },
    actiesApres(){

        return this.$store.state.actieStore.AllActies && this.$store.state.actieStore.AllActies.itemsApres ? 
          this.$store.state.actieStore.AllActies.itemsApres :
          null;
    }
  },
  components:{
    AllActiesPagina
  },
  async created(){
    await this.$store.dispatch('actieStore/getAllActies');
  }
}
</script>

<style scoped>

</style>