import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'

export const missieService = {
    getAll,
    getProjectenThemas
};

async function getAll() {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/HomePage/GetProjecten`, requestOptions)
        .then(handleResponse)
        .then(
            missies => { 
                return missies
        });
}

async function getProjectenThemas() {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/HomePage/GetProjectenThemas`, requestOptions)
        .then(handleResponse)
        .then(
            missies => { 
                return missies
        });
}