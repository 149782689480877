<template>
  <div class="fontSpecial" style="background-color: #6DABC0; color:white;Text">
    <div class="container pt-7 pb-10">
      <div class="row align-items-center">
        <div class="col-md-6 col-sm-12 col-12">
          <p>“Start jouw SKWinkel hier, waar anders?!” is een initiatief van de VZW SKWINKEL en het gemeentebestuur van Sint-Katelijne-Waver. Meer info via start@skwinkelstarter.be</p>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
          <img src="@/assets/Panden/logo1.png" class="img-fluid"/>
        </div>
        <div class="col-md-3 col-sm-6 col-6">
          <img src="@/assets/Panden/logo2.png" class="img-fluid"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>