<template>
<div class="jauneBackground">
  <div class="container">
    <div class="modTumi" id="divPr" :style="[thanks ? {'height': hauteurDiv + 'px'} : {}]">
      <div v-if="thanks" class="text-center" :style="{'padding-top': hauteurDiv - 150 + 'px'}">
          Dank voor je aankopen. Je zal een e-mail ter bevestiging ontvangen.
      </div>
      
      <div v-if="!thanks">

        <h2 class="mb-5">Aankoopformulier</h2>

        <input 
          type="text" 
          class="form-control mb-5"
          v-model="organisatieData.organisatieS_NAAM"
          placeholder="Naam organisatie">

        <div v-if="fact && fact[0]">
          <h3>Facturatiegegevens</h3>
          <p>Lorem ipsum</p>
          <input 
            type="text" 
            class="form-control mb-5"
            v-model="fact[0].factuurgegevenS_NAAM"
            placeholder="Naam">

          <input 
            type="text" 
            class="form-control mb-5"
            v-model="fact[0].factuurgegevenS_ADRES"
            placeholder="Adres">
          
          <input 
            type="text" 
            class="form-control mb-5"
            v-model="fact[0].factuurgegevenS_POSTCODE"
            placeholder="Postcode">
          
          <input 
            type="text" 
            class="form-control mb-5"
            v-model="fact[0].factuurgegevenS_GEMEENTE"
            placeholder="Gemeente">
          
          <input 
            type="text" 
            class="form-control mb-5"
            v-model="fact[0].factuurgegevenS_ONDERNEMINGSNUMMER"
            placeholder="Ondernemingsnummer">

        </div>

        <h3>Actieproducten</h3>

        <div class="row">
          <div class="col-12 col-md-4 text-center p-5" v-for="items in producten.producten" :key="items">
              <div class="card lift lift-lg shadow-light-lg mb-7">
                  <div class="card-body">
                    <h2>{{items.actieproducteN_BENAMING}}</h2>
                    <h3>{{items.actieproducteN_ACTIEPROFIEL}}</h3>
                    <p>{{items.actieproducteN_OMSCHRIJVING}}</p>
                    <div class="row">
                      <div class="col-7 text-start">
                        Eenheidsprijs:
                      </div>
                      <div class="col-5 text-end">
                        {{items.actieproducteN_EENHEIDSPRIJS}} €
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-7 text-start">
                        BTW({{items.actieproducteN_BTWTT}}%):
                      </div>
                      <div class="col-5 text-end">
                        {{items.actieproducteN_EENHEIDSPRIJS / 100 * items.actieproducteN_BTWTT}} €
                      </div>
                    </div>
                    

                    <div class="text-start mb-4">
                      <div class="row mb-4">
                        <div class="col-8 text-start">
                          Totaal beschikbaar:
                        </div>
                        <div class="col-4 text-end">
                          {{items.actieproducteN_AANTALTBESCHIKBAAR}}
                        </div>
                      </div>                    
                      <button type="button" @click="plus(items.actieproducteN_ID)" class="btn btn-warning">+</button>
                      <input type="text" class="form-control nombre mx-2" v-model="items.aantal">
                      <button type="button" @click="moins(items.actieproducteN_ID)" class="btn btn-warning">-</button><br/>
                    </div>
                    
                    <div class="row">
                      <div class="col-7 text-start">
                        Netto:
                      </div>
                      <div class="col-5 text-end">
                        {{items.totaalNetto}} €
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-7 text-start">
                        BTW:
                      </div>
                      <div class="col-5 text-end">
                        {{items.totaalBtw}} €
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-7 text-start">
                        Totaal:
                      </div>
                      <div class="col-5 text-end">
                        {{items.ttc}} €
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>

        <button type="button" @click="$emit('sluiten')" class="btn btn-danger float-start">Sluiten</button>
        <button type="button" @click="send()" class="btn btn-danger float-end">Bevestigen</button>

        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {delay} from './../../../helpers/handleResponse.js'

export default {
  name: 'ProductenPopup',
  props:{
    actieId: null,
    actie: null,
    res: null,
    organisatie:null
  },
  data:function(){
    return{
      resultat: {
        Products:[]
      },
      organisatieData: this.organisatie,
      thanks: false,
      name: "",
      factName: "",
      factAdres: "",
      factPostcode: "",
      factGemeente: "",
      factOndernemingsnummer: "",
      hauteurDiv: 0
    }
  },
  computed:{
    producten(){
      return this.$store.state.actieStore.producten ? this.$store.state.actieStore.producten.items : null;
    },
    fact(){
      return this.$store.state.organisatiesStore.facturatie;
    }
  },
  methods:{
    async send(){

      this.resultat.AirIdActie = this.actieId;
      this.resultat.AirIdOrg = this.organisatieData.organisatieS_AIRID;

      this.producten.producten.forEach(element => {
        if(element.aantal > 0)
          this.resultat.Products.push({
            "VERKOPEN_ACTIE" : this.actieId,
            "VERKOPEN_ACTIEPRODUCT": element.actieproducteN_AIRID,
            "VERKOPEN_ACTIEPROFIEL" : element.actieproducteN_ACTIEPROFIEL,
            "VERKOPEN_AANTAL" : element.aantal,
          });
      });

      if(this.resultat.Products && this.resultat.Products.length > 0){
        await this.$store.dispatch('actieStore/sendProducten', this.resultat);

        var myDiv = document.getElementById("divPr");
        this.hauteurDiv = myDiv.clientHeight;

        this.thanks = true;
        await delay(2);
        this.$emit('clicked'); 
      }
    },
    moins(id){
      this.producten.producten.forEach(element => {
        if(element.actieproducteN_ID == id && element.aantal > 0)
        {
          element.aantal--;
          this.majPrix(element);
        }
      });
    },
    plus(id){
      this.producten.producten.forEach(element => {

        if(element.actieproducteN_ID == id)
        {
          if(!element.actieproducteN_AANTALTBESCHIKBAAR || element.aantal < element.actieproducteN_AANTALTBESCHIKBAAR)
          { 
            element.aantal++;
            this.majPrix(element);
          }
        }
      })
    },
    majPrix(element){
      element.totaalNetto = (element.aantal * element.actieproducteN_EENHEIDSPRIJS).toFixed(2);
      element.totaalBtw = (element.aantal * element.actieproducteN_EENHEIDSPRIJS / 100 * element.actieproducteN_BTWTT).toFixed(2);
      element.ttc = ((element.aantal * element.actieproducteN_EENHEIDSPRIJS) + (element.aantal * element.actieproducteN_EENHEIDSPRIJS / 100 * element.actieproducteN_BTWTT)).toFixed(2);
    }
  },
  async created(){
    await this.$store.dispatch('actieStore/getProducten', this.actieId);

    if(this.organisatieData)
      await this.$store.dispatch('organisatiesStore/getFacturatie', this.organisatieData.organisatieS_AIRID);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
img{
  width:25px;
  height:25px;
}

img:hover{
  cursor: pointer;
}

.nombre
{
  width: 60px;
  display: inline-block;
  pointer-events: none;
}

.modTumi{
  border-radius:10px;
  padding:30px;
}

.bottomTumi{
  vertical-align: bottom;
}
</style>
