import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'

export const ledenService = {
    getAll
};

async function getAll() {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url()  + `/Leden/GetLeden`, requestOptions)
        .then(handleResponse)
        .then(
            retour => { 
                return retour
        });
}