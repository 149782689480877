import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'

export const actieService = {
    getProject,
    getActies,
    getActie,
    sendVoorstel,
    getVoorstellen,
    getProducten,
    getEvaluaties,
    sendEvaluatie,
    sendRegistratie,
    getIntro,
    getDoelstellingen,
    getVoorwaardenDeelnemers,
    getVoorwaardenSponsors,
    getActiePlanning,
    getCampagnePlanning,
    getSponsors,
    getDeelnemers,
    getKpi,
    getCampagnematerialen,
    sendRegistratieYes,
    sendRegistratieNo,
    sendProducten,
    getAllActies,
    getAllHomeActies
};

async function getAllHomeActies(){
    const requestOptions = {
        method: 'GET',
    };

    return await fetch(Config.Url() + `/Actie/GetAllActiesHome`, requestOptions)
        .then(handleResponse);
}

async function getAllActies(){
    const requestOptions = {
        method: 'GET',
    };

    return await fetch(Config.Url() + `/Actie/GetAllActies`, requestOptions)
        .then(handleResponse);
}


async function sendProducten(reg){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reg)
    };

    return await fetch(Config.Url() + `/Actie/InschrijvenProducten`, requestOptions)
        .then(handleResponse);
}

async function SendReg(reg){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reg)
    };

    return await fetch(Config.Url() + `/Actie/Inschrijven`, requestOptions)
        .then(handleResponse);
}

async function sendRegistratieYes(reg){
    reg.YesOrNo = true;
    SendReg(reg);
}

async function sendRegistratieNo(reg){
    reg.YesOrNo = false;
    SendReg(reg);
}


async function getIntro(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetIntro?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getDoelstellingen(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetDoelstellingen?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getCampagnematerialen(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetCampagnematerialen?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}


async function getVoorwaardenDeelnemers(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetVoorwaardenDeelnemers?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getVoorwaardenSponsors(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetVoorwaardenSponsors?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getActiePlanning(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetActiePlanning?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getCampagnePlanning(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetCampagnePlanning?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getSponsors(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetSponsors?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getDeelnemers(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetDeelnemers?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function getKpi(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetKpi?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            data => { 
                return data
        });
}

async function sendRegistratie(reg){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reg)
    };

    return await fetch(Config.Url() + `/Actie/SendRegistratie`, requestOptions)
        .then(handleResponse);
}

async function sendVoorstel(voorstel){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(voorstel)
    };

    return await fetch(Config.Url() + `/Actie/SendVoorstel`, requestOptions)
        .then(handleResponse);
}

async function getVoorstellen(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetVoorstellen?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            missies => { 
                return missies
        });
}

async function getEvaluaties(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetEvaluaties?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            missies => { 
                return missies
        });
}

async function sendEvaluatie(evaluatie){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(evaluatie)
    };

    return await fetch(Config.Url() + `/Actie/SendEvaluatie`, requestOptions)
        .then(handleResponse);
}

async function getProducten(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetProducten?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            producten => { 
                return producten
        });
}

async function getProject(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetProject?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            missies => { 
                return missies
        });
}

async function getActies(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetActies?projectId=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            missies => { 
                return missies
        });
}

async function getActie(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Actie/GetActie?id=`+ id, requestOptions)
        .then(handleResponse)
        .then(
            missies => { 
                return missies
        });
}