<template>
<div class="jauneBackground">
  <div class="container">
    <div class="modTumi text-center">
      <div v-if="thanks" class="pt-14 pb-14">
          Bedankt voor jouw suggestie!
      </div>
      
      <div v-if="!thanks">
      <div class="mb-5 text-start">
        <h2>Suggestieformulier</h2>
      </div>
        
        <input 
          type="text" 
          class="form-control mb-5"
          v-model="organisatieData.organisatieS_NAAM"
          placeholder="Naam organisatie">
        
        <input 
          type="text" 
          class="form-control mb-5"
          v-model="organisatieData.organisatieS_ETMAIL"
          placeholder="E-mail">

        <input 
          type="text" 
          class="form-control mb-5"
          v-model="organisatieData.organisatieS_MOBIELTNUMMER"
          placeholder="Mobiel nummer">

        <textarea 
          v-model="voorstel"
          class="form-control mb-5"
          :placeholder="voorstelPlaceholder"
          :style="{
            'border': voorstelPlaceholder == 'Jouw suggestie' ? '1px solid white' : '2px solid red',
          }">
        </textarea>

        <button type="button" @click="$emit('sluiten')" class="btn btn-danger float-start">Sluiten</button>
        <button type="button" @click="indienen" class="btn btn-danger float-end">Suggestie indienen</button>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {delay} from './../../../helpers/handleResponse.js'

export default {
  name: 'VoorstelPopup',
  props:{
    actieId: null,
    actie: null,
    organisatie:null,
  },
  data: function(){
    return{
      organisatieData: this.organisatie,
      voorstel:"",
      voorstelPlaceholder : "Jouw suggestie",
      thanks:false
    }
  },
  methods:{
    async indienen(){

      if(this.voorstel == "")
      {
        this.voorstelPlaceholder = "Jouw suggestie is een verplicht veld"
        return;
      }

      this.thanks = true;


      await delay(2);

      var res = {
        "name": this.organisatieData.organisatieS_NAAM,
        "email": this.organisatieData.organisatieS_ETMAIL,
        "mobiel":this.organisatieData.organisatieS_MOBIELTNUMMER,
        "voorstel": this.voorstel,
        "actieId": this.actieId,
        "orgAirId":this.organisatieData.organisatieS_AIRID
      }

      await this.$store.dispatch('actieStore/sendVoorstel', res);

      this.$emit('clicked');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modTumi{
  border-radius:10px;
  padding:30px;
}

.back{
  background: #c3c7de;
}
</style>
