<template>
<section class="tumiBackground pt-10">
  <div class="container">
    <h2 class="mt-5">Zones</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate</p>
    
    <Filters v-if="zonesMod" :zones="zonesMod" />

    <div class="list-group list-group-flush mt-5">        
        <div v-for="item in orgs" :key="item" class="list-group-item card">
          <div class="row align-items-center mt-5">
              <div class="col">
                <Organisatie 
                  :naam="item.organisatieS_NAAM"
                  :adres="item.organisatieS_STRAAT + ' ' + item.organisatieS_GEMEENTE"
                  :email="item.organisatieS_ETMAIL"
                  :tel="item.organisatieS_TELEFOONNUMMER"
                  :mobiel="item.organisatieS_MOBIELTNUMMER"
                  :web="item.organisatieS_WEBSITE"
                  :Facebook="item.organisatieS_FACEBOOKPAGINA"
                  :Insta="item.organisatieS_INSTAGRAMPROFIEL"
                  :logo="item.organisatieS_LOGO"
                /> 
            </div>
          </div>
        </div>
    </div>

  </div>
</section>
</template>

<script>
import Filters from '../../components/Organisaties/Filters.vue'
import Organisatie from '../../components/Organisaties/Organisatie.vue'

export default {
  name: 'Panden',
  components: {
    Filters,
    Organisatie
  },
  data:function(){
    return{
      voorstelling: null,
      zonesMod: null
    }
  },
  computed:{
    orgs(){
      return this.$store.state.organisatiesStore.organisaties;
    },  
    zones(){
      return this.$store.state.homeStore.panden ? this.$store.state.homeStore.panden : null;
    }
  },
  watch:{
    zones(e){
      e.listZones.forEach(element => {
        element.zoneS_RUIMTE = e.listRuimtes.find(i=>i.ruimteS_AIRID == JSON.parse(element.zoneS_RUIMTE)[0]).ruimteS_BENAMING;
      });

      this.zonesMod = e.listZones;
    }
  },
  async created(){
    await this.$store.dispatch('homeStore/getPanden');
    await this.$store.dispatch('organisatiesStore/getOrganisaties');
  },
}  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>