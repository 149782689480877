<template>
  <BannerPanden :panden="panden"/>
</template>

<script>
import BannerPanden from '../../components/Panden/PandenPagina/BannerPanden.vue'

export default {
  name: 'Panden',
  components: {
    BannerPanden
  },
  computed:{
    panden(){
        return this.$store.state.pandenStore.pandenOne;
    }
  },
  async created(){
    await this.$store.dispatch('pandenStore/getPandenOne');
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>